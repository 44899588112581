import { SearchBar } from 'antd-mobile';
import { LeftOutline } from 'antd-mobile-icons'
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setConfigParams,clearFilterWithoutSearchWord, clearFilter} from '../TemplateList/indexSlice';
import './index.scss'
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useQueryParam from '@/hooks/useQueryParam';

const SearchWord = ({ onSearch }) => {
    const searchRef = useRef(null)
    const navigate = useNavigate()
    const selectConfig = useSelector(state => state.mobileIndex.filter)
    const jzOrigin = useQueryParam('jz_origin')
    const [data, setData] = useState(['测试', 'Java开发工程师', '产品经理简历', '校招简历', '实习简历', '应届生简历', 'UI设计师简历', '销售代表简历', '广告传媒'])
    const [searchValue, setSearchValue] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectConfig.searchWord) {
            setSearchValue(selectConfig.searchWord)
        }
    }, [])

    const handleSearch = (value) => {
        dispatch(clearFilterWithoutSearchWord({ type: 'searchWord', value }))
        navigate('/resume/mobile/template',{replace:true})
    };

    const handleBack = () => {
        if (jzOrigin === 'promo') {
            navigate('/resume/mobile/index',{replace:true})
            dispatch(clearFilter({}))
        } else {
            navigate('/resume/mobile/index',{replace:true})
            dispatch(clearFilter({}))
        }
    }

    const handleClickTag = (item) => {
        setSearchValue(item)
        searchRef.current?.focus()
    }

    return (
        <div className='page-search'>
            <div className="search-header">
                <LeftOutline className='icon' onClick={handleBack} />
                <SearchBar placeholder='输入行业、职位，搜索简历模板' ref={searchRef} value={searchValue} onChange={v => setSearchValue(v)} className='input' onSearch={handleSearch} showCancelButton={() => true} />
            </div>

            <div className="content">
                <div className='title'>热门搜索</div >
                <div className='jz-tag-list'>
                    {data.map(item => {
                        const cls = classNames({
                            'tag': true,
                            active: item === searchValue
                        })
                        return <div key={item} className={cls} onClick={() => handleClickTag(item)} >{item}</div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default SearchWord;
