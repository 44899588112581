export const isDiscardAi = __DEV__ ?
(window.location.hostname === 'test.miaochuppt.com')
 :
(window.location.hostname === 'miaochuppt.com')


export const mapErrorCodeToConfigInfo = {
    // ai点数
    10003: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10006: {
        vipType: 'Animation_svip',
        payInfoVipType: 'Animation',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10007: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10008: {
        vipType: 'Media_svip',
        payInfoVipType: 'Media',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10009: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10010: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10011: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '很抱歉，达到使用额度限制，请联系客服',
        upgrade: false,
    },
    10012: {
        vipType: 'Prezi_svip',
        payInfoVipType: 'Prezi',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10013: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10014: {
        vipType: 'Resume_svip',
        payInfoVipType: 'Resume',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    10015: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: isDiscardAi ? '额度超出限制' : '生成超出限制',
        tipText: '达到使用额度限制，请升级',
        upgrade: true,
    },
    // 下载失败
    10103: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: 'PPT模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10104: {
        vipType: 'Animation_svip',
        payInfoVipType: 'Animation',
        tipTitle: '下载超出限制',
        tipText: '动画模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10105: {
        vipType: 'Animation_svip',
        payInfoVipType: 'Animation',
        tipTitle: '下载超出限制',
        tipText: '动画模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10106: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '动画模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10107: {
        vipType: 'Media_svip',
        payInfoVipType: 'Media',
        tipTitle: '下载超出限制',
        tipText: '视频模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10108: {
        vipType: 'Media_svip',
        payInfoVipType: 'Media',
        tipTitle: '下载超出限制',
        tipText: '视频模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10109: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '视频模板下载达到使用限制，请升级',
        upgrade: true,
    },
    10110: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10112: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10111: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '很抱歉，下载达到使用限制',
        upgrade: false,
    },
    10113: {
        vipType: 'Prezi_svip',
        payInfoVipType: 'Prezi',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10114: {
        vipType: 'Prezi_svip',
        payInfoVipType: 'Prezi',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10115: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10116: {
        vipType: 'Resume_svip',
        payInfoVipType: 'Resume',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10117: {
        vipType: 'Resume_svip',
        payInfoVipType: 'Resume',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    10118: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '下载超出限制',
        tipText: '下载达到使用限制，请升级',
        upgrade: true,
    },
    // 工作台上限
    10203: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10204: {
        vipType: 'Animation_svip',
        payInfoVipType: 'Animation',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10205: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10206: {
        vipType: 'Media_svip',
        payInfoVipType: 'Media',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10207: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10208: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10209: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '很抱歉，工作台同时编辑文件数量达到使用限制，请前往工作台删除无用的文件',
        upgrade: false,
    },
    10210: {
        vipType: 'Prezi_svip',
        payInfoVipType: 'Prezi',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10211: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10212: {
        vipType: 'Resume_svip',
        payInfoVipType: 'Resume',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
    10213: {
        vipType: 'All_svip',
        payInfoVipType: 'All',
        tipTitle: '数量超出限制',
        tipText: '工作台同时编辑文件数量达到使用限制，请升级',
        upgrade: true,
    },
}
