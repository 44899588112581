import ModuleBox from "../ModuleBox"
import useQueryParam from "@/hooks/useQueryParam";
import { useNavigate } from "react-router-dom";
import useQuerySlot from "@/pages/Mobile/hooks/useQuerySlot";
import moment from "moment";
import { ContentItem, TargetItem, TargetItemDate, TargetItemDetail } from '../../styled'
import styled from "styled-components";
import { Ellipsis } from "antd-mobile";

const TargetItemImageWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 6px;
    row-gap: 6px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F4F4F4;
    img {
        border-radius: 8px;
        border: 1px solid #E4E4E4;
        width: 100%;
    }
`

const TargetLinkItem = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F4F4F4;

    &:last-child {
        padding-bottom: 5px;
        border-bottom: none;
    }
`



const Portfolio = ({ data = {}, moduleName }) => {
    const resumeId = useQueryParam('id')
    const navigate = useNavigate()
    const title = useQuerySlot({
        moduleName,
        slotName: "title_text"
    })

    const handleClickContent = (index) => {
        navigate(`/resume/mobile/edit/general?id=${resumeId}&name=${moduleName}&contentIndex=${index}`)
    }

    // const contentList = data
    return <ModuleBox name={moduleName} title={title || "求职意向"}>
        <TargetItemDetail>
            <TargetItemImageWrap>
                {
                    data.images?.map((item, index) => {
                        return <img key={index} src={item.src} />
                    })
                }
            </TargetItemImageWrap>
            <div>
                {
                    data.links?.map((item, index) => {
                        return <TargetLinkItem key={index}>
                            <Ellipsis direction='end' rows={4} content={item.title} />
                            <div className="pt-12" style={{ color: '#11BBA6'}}>{item.link}</div>
                        </TargetLinkItem>
                    })
                }
            </div>
            
        </TargetItemDetail>
        {/* {
            contentList.map((content = {}, index) => {
                const { images, links } = content
                console.log('images', images)
                return <ContentItem className="content-item" key={index} onClick={() => handleClickContent(index)}>
                    
                    <TargetItemDate>
                        <div>{content.role}</div>
                        <div>
                            {moment(content.start_date).format("YYYY.MM")}-{moment(content.end_date).format("YYYY.MM")}
                        </div>
                    </TargetItemDate>
                    <TargetItemDetail>
                        {content.details}
                    </TargetItemDetail>
                </ContentItem>
            })
        } */}

    </ModuleBox>
}

export default Portfolio
