import styled from "styled-components"
import FlexStyled from "@/styled/FlexStyled";
import {useNavigate} from "react-router-dom";
import {useStore} from "react-redux";
import {useResumeSelector, useResumeStore} from "@/components/resume/redux/hooks";
import isVip2 from "@/common/isVip";
import {message} from "antd";
import {downPdf} from "@/http/api";
import fetchFile from "@/common/fetchFile";
import downFile from "@/common/downFile";
import useQuery from "@/hooks/useQuery";

const ContainerStyled = styled.div`
  height: 50px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const BackgroundButtonStyled = styled.div`
  width: 176px;
  height: 32px;
  background: #ECEFF1;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

`
const BackgroundButtonIconStyled = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 30px;

  polygon {
    fill: #6E727C;
  }
`
const BackgroundButtonTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6E727C;
  line-height: 14px;
  margin-left: 8px;
`

const icon = <div dangerouslySetInnerHTML={{
    __html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="下载提示" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.604553">
        <g id="点击下载显示预览状态" transform="translate(-45.000000, -13.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组-6" transform="translate(15.000000, 9.000000)">
                <g id="编组-3" transform="translate(30.000000, 4.000000)">
                    <g id="fanhui" transform="translate(5.000000, 7.500000)">
                        <polygon id="路径" points="14 5.16889972 13.999507 3.82210375 2.5729712 3.82620743 5.39784971 0.918908491 4.37280218 0 0 4.5 4.37280218 9 5.39784971 8.08109151 2.57214958 5.1730034"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
}}/>
export default () => {
  const query = useQuery();
  const { id } = query;
    const navigate = useNavigate();

    const onClickBack = ()=>{
      navigate(`/resume/resume?id=${id}`)
    }

    return <ContainerStyled>
        <BackgroundButtonStyled onClick={onClickBack}>
            <BackgroundButtonIconStyled>
                {icon}
            </BackgroundButtonIconStyled>
            <BackgroundButtonTextStyled>返回编辑简历</BackgroundButtonTextStyled>
        </BackgroundButtonStyled>
    </ContainerStyled>
}
