import MobileLayout from "../../layout"
import UserAnswer from "./question/UserAnswer"
import SystemAnswer from "./question/SystemAnswer"
import { useEffect, useRef, useState } from "react"
import useSelector from "@/components/resume/redux/hooks/useSelector"
import useDispatch from "@/components/resume/redux/hooks/useDispatch"
import interviewSlice from "@/components/resume/redux/services/interviewSlice"
import useQueryParam from "@/hooks/useQueryParam"
import { getInterviewQuestionList, getInterviewReportPdf,getInterviewQuestionListTemplate } from "@/http/api"
import SaveModal from "./question/components/SaveModal"
import { message } from 'antd'
import { useNavigate } from "react-router-dom"
import CommonModal from "./question/components/CommonModal"
import {
    icon1,
    icon2,
    QuestionCard,
    QuestionHeader,
    QuestionCardHeader,
    QuestionListItemStyled,
    processInterviewData,
    updateInterviewStatus,
    downloadPdf
} from "./const"
import isPay,{ isSVIP } from "@/common/isVip";
import PayModal from "../../components/Modal/PayModal";
import isLogin from "@/common/isLogin"
import {
    useSelector as useReduxSelector,
    useDispatch as useReduxDispatch,
  } from "react-redux";
  import mobileSlice, { setPageStatus } from "@/pages/Mobile/mobileSlice";

const Question = () => {

    const resumeId = useQueryParam('id')
    const queryJdId = useQueryParam('jdId')
    const isTemplate = useQueryParam('isTemplate')
    const questionList = useSelector(state => state.interview.list)
    const selectJdId = useSelector(state => state.interview.selectJdId)
    const isContentModified = useSelector(state => state.interview.isContentModified)
    const pageStatus = useSelector(state => state.interview.pageStatus)
    const dispatch = useDispatch()
    const [selectIndex, setSelectIndex] = useState(0)
    const [saveVisible, setSaveVisible] = useState(false)
    const questionIndex = useSelector(state => state.interview.pageStatus.pageIndex)
    const timeRef = useRef(0)
    const reduxDispatch = useReduxDispatch();
    const pageReduxStatus = useReduxSelector(state => state.mobile?.pageStatus)
    

    const navigate = useNavigate()
    useEffect(() => {
        // queryJdId
        console.log(pageReduxStatus?.times)
        fetchQuestionList(resumeId, queryJdId || selectJdId )
        reduxDispatch(
            setPageStatus({
              pageStatus: {
                status: 2,
                id: resumeId,
                jdId:   queryJdId,
                pageIndex: 0,
                isTemplate: isTemplate,
                times:0
              },
            })
          );
    }, [])

    const updateSelectStatus = (index) => {
        setSelectIndex(index)
        // 选中后，要重新更新list的状态
        const newList = updateInterviewStatus(questionList)
        dispatch(interviewSlice.actions.setList(newList))
    }
    const handlePaySuccess = () => {
        window.location.reload()
    }
    const fetchQuestionList = (resumeId, selectJdId) => {
        // setLoading(true)
        return new Promise((resolve, reject) => {
            // 当selectJd不存在时，从query参数里拿
            const idTitle = isTemplate == 'true' ? 'jobDescriptionTemplateId' : 'userResumeJobDescriptionId'
            const func = isTemplate == 'true' ? getInterviewQuestionListTemplate : getInterviewQuestionList
            console.log(isTemplate)
            func({ query: { resumeId, [idTitle]: selectJdId } }).then((data) => {
                if (data && data.length > 0) {
                   
                    // answer
                    if(timeRef.current===0){
                        data.forEach((v)=>{
                            if(v.answer && v.answer.length>0){
                                v.answer =''
                            }
                        })
                        timeRef.current =+ 1
                    }
                    // 清空
                    const newList = processInterviewData(data, questionList).map((v)=>{
                        return {
                            ...v,
                            answer:'',
                            systemAnswer:''
                        }
                    })
                    dispatch(interviewSlice.actions.setList(newList))
                    resolve(newList)
                } else {
                    resolve([])
                }
            }).catch((err) => {
                reject(err)
            }).finally(() => {
                // setLoading(false)
            })
        })
    }

    const handleSaveConfirm = () => {
        setSaveVisible(false)
        // updateSelectStatus(preClickRef.current)
        dispatch(interviewSlice.actions.setIsContentModified(false))
        reGeneration()
    }

    const handleChangeStatus = async (value, id, type, data) => {
        console.log(value, id, type, data)
        let list = questionList
        if (value === 'feedback') {
            // 需要重新更新一下接口

            // list = await fetchQuestionList(resumeId, selectJdId || queryJdId)

        }
        const newList = list.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    answer: data?.answer || item.answer,
                    systemAnswer: data?.reference|| item.systemAnswer,
                    [type]: value,
                    feedback: data?.feedback || item.feedback
                }
            }
            return item
        })
        dispatch(interviewSlice.actions.setList(newList))
    }

    const handleSelectIndex = (e) => {
        if (isContentModified) {
        } else {
            updateSelectStatus(0)
        }
    }
    

    const handleDownload = () => {
        if(!isSVIP()){
            setCommonModalVisible(true)
            setCommonModalContent('下载答题报告为SVIP专属功能，请先升级!')
            return
        }
        message.loading({
            content: '下载中...',
            duration: 0
        })
        getInterviewReportPdf({
            query: {
                resumeId: resumeId,
                userResumeJobDescriptionId: selectJdId || queryJdId
            }
        }).then(pdfLink => {
            downloadPdf(pdfLink, '答题报告')
        }).finally(() => {
            message.destroy()
        })
    }

    const [payModalVisible, setPayModalVisible] = useState(false)

    const reGeneration = () => {
        dispatch(interviewSlice.actions.setPageStatus({
            status: 1,
            id: null,
            jdId: null,
            pageIndex:0
        }))
        reduxDispatch(
            setPageStatus({
              pageStatus: {
                status: 2,
                id: 0,
                jdId: 0,
                pageIndex: 0,
                isTemplate: false,
                times:0
              },
            })
          )
        navigate(`/resume/mobile/interview`)
    }
    const [commonModalVisible,setCommonModalVisible] = useState(false)
    const [commonModalContent,setCommonModalContent] = useState('')
    const sureText = '升级'
    const leftFunc = ()=>{
        dispatch(interviewSlice.actions.setPageIndex(pageStatus.pageIndex - 1))
    }

    const rightFunc = ()=>{
        dispatch(interviewSlice.actions.setPageIndex(pageStatus.pageIndex + 1))
    }

    return (
        <MobileLayout>
            <QuestionHeader>
                <div className="item1">📝面试问题(5)</div>
                <div onClick={handleDownload} className="item2">{icon1}下载报告</div>
                <div onClick={()=>setSaveVisible(true)} className="item3">{icon2}重新生成</div>
            </QuestionHeader>
            <QuestionCard>
                <QuestionCardHeader>
                    <div style={{visibility: questionIndex > 0 ? 'visible' : 'hidden'}} onClick={leftFunc} className="btn-pre">上一题</div>
                    {questionIndex + 1} / {questionList.length}
                    <div style={{visibility: questionIndex < questionList.length - 1 ? 'visible' : 'hidden'}} onClick={rightFunc} className="btn-next">下一题</div>
                </QuestionCardHeader>
                {
                    questionList.length > 0 && <QuestionListItemStyled
                        $active={true}
                        onClick={(e) => handleSelectIndex(e)}
                        className="q-item"
                    >
                        <div className="question">Q{questionIndex + 1}：“{questionList[questionIndex].question}”</div>
                        <div className="strategy">
                            <p className="tip">解题思路：</p>
                            <p>{questionList[questionIndex].strategy}</p>
                        </div>
                        <UserAnswer
                            questionId={questionList[questionIndex].id}
                            answerId={questionList[questionIndex].answerId}
                            feedback={questionList[questionIndex].feedback}
                            answer={questionList[questionIndex].answer}
                            isActive={true}
                            status={questionList[questionIndex].status}
                            onChange={(value, data) => handleChangeStatus(value, questionList[questionIndex].id, 'status', data)}
                        />
                        <SystemAnswer
                            questionId={questionList[questionIndex].id}
                            referenceId={questionList[questionIndex].systemAnswerId}
                            isActive={true}
                            answer={questionList[questionIndex].systemAnswer}
                            status={questionList[questionIndex].systemStatus}
                            onChange={(value,data) => handleChangeStatus(value, questionList[questionIndex].id, 'systemStatus',data)}
                        />
                    </QuestionListItemStyled>
                }
                <SaveModal
                    visible={saveVisible}
                    onCancel={() => setSaveVisible(false)}
                    onConfirm={() => handleSaveConfirm()}
                />
            </QuestionCard>
            <CommonModal visible={commonModalVisible} onConfirm={() => {setCommonModalVisible(false);setPayModalVisible(true)} } onCancel={() => setCommonModalVisible(false)} sureText={sureText} content={commonModalContent } />
            <PayModal defaultVipType='svip' visible={payModalVisible} onClose={() => setPayModalVisible(false)} onSuccess={handlePaySuccess} />

        </MobileLayout>
    )
}

export default Question