import styled from 'styled-components';
import withComponent from '@/hoc/withComponent';
import React, {useEffect, useRef, useState} from 'react';
import FlexStyled from '@/styled/FlexStyled';
import LogoComponent from "@/components/Logo"
import User from '@/components/user/index';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
`;

const TabListStyled = styled.div`
    display: flex;
    margin-right: auto;
    align-items: center;
    position: relative;
    .tab-item{
        margin-left: 50px;
        color: #2c2c2c;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
    .line{
        position: absolute;
        background: #0A5FF0;
        width: 24px;
        height: 3px;
        border-radius: 3px;
        bottom: -7px;
        right: 1px;
    }
`

const TabsSection = styled.div`
    display: flex;
    align-items: center;
    column-gap: 50px;
    margin-right: 50px;
    color: #2C2C2C;
    font-size: 14px;
    .tab-1,
    .tab-2{
        cursor: pointer;
    }
`

const navList = [
    {
        id:1,
        name: 'PPT',
        nav:'/pptx'
    },
    {
        id:2,
        name: '巨幕',
        nav:'/pptx/prezi'
    },
    {
        id:3,
        name: '视频',
        nav:'/pptx/video'
    },
    {
        id:4,
        name: '动画',
        nav:'/pptx/mg'
    },
    {
        id:5,
        name: '简历',
        nav:'/resume'
    },
]


export default withComponent((props) => {

    const navigate = useNavigate()
    

    const navToPage = (type) => {
        navigate(`/resume/resume?tabKey=${type}`)
    }

    const handleClickNav = (nav) => {
        window.location.href = nav
    }

    return (
        <Container>
            <FlexStyled $flexShrink={0} $justifyContent='center' style={{}}>
                <FlexStyled $justifyContent='space-between' $alignItems='center'
                            style={{width: '100%', height: 60, padding: '0 50px', position: 'relative'}}>
                    <div>
                        <LogoComponent isDark={false}/>
                    </div>
                    <TabListStyled className='tab-list'>
                            {
                                navList.map((item, index) => {
                                    return <div key={item.id} onClick={() => handleClickNav(item.nav)} className='tab-item'>{item.name}</div>
                                })
                            }
                            <div className='line' />
                    </TabListStyled>
                    <FlexStyled $alignItems='center'>
                        <TabsSection>
                            <div onClick={() => navToPage('resume')} className='tab-1'>改简历</div>
                            <div onClick={() => navToPage('question')} className='tab-2'>练面试</div>
                        </TabsSection>
                        <User/>
                    </FlexStyled>
                </FlexStyled>
            </FlexStyled>
        </Container>
    );
});

