import Header from "./Header";
import {
  getDataFromTemplate,
  getJobTypesAll,
  getResumeDataById,
  listResumeUser,
} from "@/http/api";
import useQuery from "@/hooks/useQuery";
import styled from "styled-components";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Resume from "@/components/resume/index";
import { createStore } from "@/components/resume/redux/store";
import useResumeEdit from "@/hooks/useResumeEdit";
import { useNavigate } from "react-router-dom";
import strictStringify from "@/common/strictStringify";
import ModuleController from "@/pages/ResumeDetail/ModuleController";
import ThemeController from "@/pages/ResumeDetail/ThemeController";
import { ResumeReduxContext } from "@/components/resume/redux/context";
import { Provider, useStore } from "react-redux";
import { message, Skeleton } from "antd";
import LoginModal from "@/components/user/LoginModal";
import PayModal from "@/components/PayModal";
import { ResumeDetailInstanceContext } from "@/pages/ResumeDetail/context";
import ChangeTemplateModal from "@/components/ChangeTemplateModal";
import ImportModal from "@/components/ImportModal";
import ResumeLoading from "@/pages/ResumeDetail/ResumeLoading";
import withConfigProvider from "@/hoc/withConfigProvider";
import { createGlobalStyle } from "styled-components";
import dispatchResumeData from "@/common/resume/dispatchResumeData";
import Chat from "@/pages/ResumeDetail/Chat";
import TargetPosition from "./component/TargetPosition";
import MyResume from "./component/MyResume";
import LeftTabList from "./component/LeftTabList";
import FlexStyled from "@/styled/FlexStyled";
import Interview from "./component/Interview";
import TransitInterView from "./component/TransitInterView";
import interviewSlice from "@/components/resume/redux/services/interviewSlice";
import LoginTip from "./component/LoginTip";
import Version from "@/common/resume/version";
import templateSlice from "@/components/resume/redux/services/templateSlice";
import resumeSlice from "@/components/resume/redux/services/resumeSlice";
import resumeDataSlice from "@/components/resume/redux/services/resumeDataSlice";
import themeSlice from "@/components/resume/redux/services/themeSlice";
import paginationSlice from "@/components/resume/redux/services/paginationSlice";
import "@/pages/Home/index.scss";
import EmptyResumeModal from "./component/EmptyResumeModal";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
const ContainerStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #eceff1;
`;
const ContentPositionStyled = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;
const ContentStyled = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 1280px;
  justify-content: center;
`;
const ResumeContainerStyled = styled.div`
  flex-shrink: 0;
  padding-top: 16px;
  margin-bottom: 16px;
  /* padding-bottom: 16px; */
  width: 824px;
  display: flex;
  flex-direction: column;
`;
const Resume2ContainerStyled = styled.div`
  position: relative;
  flex-grow: 1;
`;

const ResumeWrapStyled = styled.div`
  ${(props) => props.tabKey === "resume" && `display: flex;`}
  ${(props) => props.tabKey === "resume" && `flex: 1;`} /* ${(props) =>
    props.tabKey === "question" && `overflow-y: scroll;`} */
`;
const ResumeRightStyled = styled.div`
  display: flex;
  width: 360px;
  flex-direction: column;
  padding-top: 16px;
`;

const EmptyResumeStyled = styled.div`
  width: 100%;
  background: url("https://oss.jianzeppt.com/asd2.png");
  height: 820px;
  background-size: cover;
`;

export default withConfigProvider(() => {
  const query = useQuery();
  const bodyRef = useRef();
  const { id, create_flag, test_data_flag, tabKey: queryKey } = query;
  const resumeRef = useRef();
  const instance = useRef({
    templateId: undefined,
    name: undefined,
    resumeRef,
    version: new Version(),
  }).current;
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const resumeContainerStyledRef = useRef();
  const [moduleVisible, setModuleVisible] = useState(false);
  const [changeTemplateVisible, setChangeTemplateVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(true);
  const [themeVisible, setThemeVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [tabKey, setTabKey] = useState("resume");
  const [width, setWidth] = useState(undefined);
  const [emptyResumeVisible, setEmptyResumeVisible] = useState(false);
  const [resumeList, setResumeList] = useState([]);
  const resumeStore = useMemo(
    () =>
      createStore({
        devTools: __PRO__ ? false : { name: "简历" },
      }),
    []
  );
  const onClickDown = async () => {
    navigate(`/resume/down/client?id=${id}`, { state: resumeStore.getState() });
  };

  const fetchData = async () => {
    let resumeId = id;
    if (create_flag === "1") {
      const { id: resId } = await getDataFromTemplate({
        query: { resumeId: id },
      }).catch((err) => {
        // message.error(err.msg)
        // setTimeout(() => {
        //     navigate("/workspace")
        //     message.destroy()
        // }, 2000)
      });
      if (!resId) {
        return;
      }
      resumeId = resId;
      const query = { id: resumeId };
      navigate(location.pathname + strictStringify(query), { replace: true });
    }
    const resumeData = await getResumeDataById(resumeId).catch((err) => {
      message.error(err.msg);
    });
    if (!resumeData) return;
    dispatchResumeData(resumeStore.dispatch, resumeData);
    instance.templateId = resumeData.template.id;
    instance.name = resumeData.resume.name;
    setWidth(820);
    setResumeLoading(false)
    console.log("resumeData", resumeData);
  };
  const fetchData2 = async () => {
    let resumeId = id;
    const resumeData = await getResumeDataById(resumeId).catch((err) => {
      message.error(err.msg);
    });
    if (!resumeData) return;
    resumeStore.dispatch(templateSlice.actions.set(resumeData.template));
    resumeStore.dispatch(themeSlice.actions.set(resumeData.theme));
    instance.templateId = resumeData.template.id;
    instance.name = resumeData.resume.name;
  };
  window.fetchData = fetchData2;
  window.setShow = setShow;

  // 获取求职意向数据
  const fetchJdData = () => {
    getJobTypesAll().then((res) => {
      if (res) {
        resumeStore.dispatch(interviewSlice.actions.setJdList(res));
      }
    });
  };

  const { onChange, onInit } = useResumeEdit(resumeStore);

    //这里是为了处理首页跳转过来练面试或者改简历
  useEffect(() => {
    if (id) {
      fetchData();
      fetchJdData();
    } else {
      listResumeUser({
        page: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.dataList.length) {
          const id = res.dataList[0].id;
          if (queryKey === "resume") {
            navigate(`/resume/resume?id=${id}`);
          } else {
            navigate(`/resume/resume?id=${id}`);
            setTabKey(queryKey);
          }
        } else {
          if (queryKey === "resume") {
            setEmptyResumeVisible(true);
          } else {
            setTabKey(queryKey);
          }
        }
      });
    }
  }, [id]);

  const handleChangeTabFromHeader = (key) => {
    if (emptyResumeVisible) return;
    if (!width) return;
    if (key === "question") {
      listResumeUser({
        page: 1,
        pageSize: 10,
      }).then((res) => {
        setResumeList(res.dataList);
        setTabKey(key);
      });
    } else {
      setTabKey(key);
    }
  };

  useEffect(() => {
    listResumeUser({
      page: 1,
      pageSize: 10,
    }).then((res) => {
      setResumeList(res.dataList);
    });
  }, []);

  const [isImportant,setIsImportant] = useState(false)
  const myResumeSetLoading = (v,type)=>{
    if(type==='import'){
      setResumeLoading(v)
      setIsImportant(v)
      return
    }
    setResumeLoading(v)
  }

  window.tabKey = tabKey
  return (
    <ResumeDetailInstanceContext.Provider value={instance}>
      <Provider context={ResumeReduxContext} store={resumeStore}>
        <GlobalStyle />
        <LoginModal
          getContainer={null}
          visible={loginVisible}
          onSuccess={() => setLoginVisible(false)}
          onCancel={() => setLoginVisible(false)}
        />
        <PayModal
          id={1}
          visible={payModalVisible}
          onSuccess={() => setPayModalVisible(false)}
          onCancel={() => setPayModalVisible(false)}
        />
        <ContainerStyled>
          <Header
            store={resumeStore}
            resumeRef={resumeRef}
            onClickDown={onClickDown}
            setLoginVisible={setLoginVisible}
            setThemeVisible={setThemeVisible}
            setImportModalVisible={setImportModalVisible}
            setChangeTemplateVisible={setChangeTemplateVisible}
            onClickModule={() => setModuleVisible(true)}
            setResumeLoading={setResumeLoading}
            tabKey={tabKey}
            onChangeTab={handleChangeTabFromHeader}
          />
          <ThemeController
            visible={themeVisible}
            onClose={() => setThemeVisible(false)}
          />
          <ModuleController
            visible={moduleVisible}
            onClose={() => setModuleVisible(false)}
          />
          <ChangeTemplateModal
            templateId={instance.templateId}
            visible={changeTemplateVisible}
            onCancel={() => setChangeTemplateVisible(false)}
          />
          <ImportModal
            visible={importModalVisible}
            onCancel={() => setImportModalVisible(false)}
          />
          <ContentPositionStyled ref={bodyRef}>
            <ContentStyled>
              <ResumeContainerStyled ref={resumeContainerStyledRef}>
                {!emptyResumeVisible ? (
                  <ResumeWrapStyled tabKey={tabKey}>
                    {/* <LeftTabList activeKey={tabKey} onChange={(key) => setTabKey(key)} /> */}
                    {tabKey === "resume" && (
                      <Resume2ContainerStyled style={{ opacity: show ? 1 : 0 }}>
                        <MyResume
                          resumeRef={resumeRef}
                          onClickDown={onClickDown}
                          setThemeVisible={setThemeVisible}
                          setImportModalVisible={setImportModalVisible}
                          setChangeTemplateVisible={setChangeTemplateVisible}
                          setResumeLoading={myResumeSetLoading}
                          onClickModule={() => setModuleVisible(true)}
                        />
                        {resumeLoading && isImportant && <ResumeLoading />}
                        <Scrollbars
                          autoHide
                          style={{ height: "calc(100% - 58px)" }}
                        >
                          <Skeleton
                            className="use-skeleton"
                            loading={resumeLoading && (!isImportant)}
                            round
                            active
                            avatar
                            paragraph={{
                              rows: 8,
                              width: [
                                "80%",
                                "80%",
                                "80%",
                                "80%",
                                "80%",
                                "80%",
                                "80%",
                                "80%",
                              ],
                            }}
                          >
                            <div
                              style={{
                                pointerEvents: resumeLoading
                                  ? "none"
                                  : undefined,
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: 12,
                              }}
                            >
                              {!!width && (
                                <Resume
                                  resumeRef={resumeRef}
                                  version={instance.version}
                                  getModuleContentToolContainer={() =>
                                    bodyRef.current
                                  }
                                  onInit={onInit}
                                  openEdit
                                  width={width}
                                  store={resumeStore}
                                  onChange={onChange}
                                />
                              )}
                            </div>
                          </Skeleton>
                        </Scrollbars>
                      </Resume2ContainerStyled>
                    )}
                  </ResumeWrapStyled>
                ) : tabKey === "resume" ? (
                  <EmptyResumeStyled>
                    <EmptyResumeModal
                      title="暂无简历，请新建简历"
                      btnText="新建简历"
                      onClick={() => navigate("/resume")}
                    />
                  </EmptyResumeStyled>
                ) : (
                  <></>
                )}
                {
                  // tabKey === 'question' && <Interview />
                  <TransitInterView
                    resumeList={resumeList}
                    style={{
                      display: tabKey === "question" ? "block" : "none",
                    }}
                  />
                }
              </ResumeContainerStyled>
              {/* <ResumeRightStyled> */}
              {/* <TargetPosition 
                                tabKey={tabKey} 
                                setResumeLoading={setResumeLoading}
                                onChangeTab={(key) => setTabKey(key)}
                            /> */}
                            <Chat 
                                resumeRef={resumeRef} 
                                store={resumeStore} 
                                setResumeLoading={setResumeLoading}
                                setThemeVisible={setThemeVisible}
                                setChangeTemplateVisible={setChangeTemplateVisible}
                            />
                        {/* </ResumeRightStyled> */}
                    </ContentStyled>
                </ContentPositionStyled>

          <LoginTip onLogin={() => setLoginVisible(true)} />
        </ContainerStyled>
      </Provider>
    </ResumeDetailInstanceContext.Provider>
  );
});
