import ModuleBox from "../ModuleBox"
import useQueryParam from "@/hooks/useQueryParam";
import { useNavigate } from "react-router-dom";
import useQuerySlot from "@/pages/Mobile/hooks/useQuerySlot";
import moment from "moment";
import { ContentItem, TargetItem, TargetItemDate, TargetItemDetail } from '../../styled'

const WorkExperience = ({ data = {}, moduleName }) => {
    const resumeId = useQueryParam('id')
    const navigate = useNavigate()
    const title = useQuerySlot({
        moduleName,
        slotName: "title_text"
    })

    const handleClickContent = (index) => {
        navigate(`/resume/mobile/edit/general?id=${resumeId}&name=${moduleName}&contentIndex=${index}`)
    }

    const contentList = data['content'] || []
    return <ModuleBox name={moduleName} title={title || "求职意向"} addIndex={contentList.length}>
        {
            contentList.map((content = {}, index) => {
                return <ContentItem className="content-item" key={index} onClick={() => handleClickContent(index)}>
                    <TargetItem>{content.company}</TargetItem>
                    <TargetItemDate>
                        <div>{content.position}</div>
                        <div>
                            {moment(content.start_date).format("YYYY.MM")}-{moment(content.end_date).format("YYYY.MM")}
                        </div>
                    </TargetItemDate>
                    <TargetItemDetail>
                        {content.details}
                    </TargetItemDetail>
                </ContentItem>
            })
        }

    </ModuleBox>
}

export default WorkExperience
