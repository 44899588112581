import JzImage from "@/components/JzImage"
import config from "@/const"
import withComponent from "@/hoc/withComponent"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const FooterStyled = styled.div`
    background: #222222;
    color: #777777;
    padding-top: 36px;
    .logo-img {
        width: 131px;
        height: 34px;
        margin-bottom: 40px;
    }
`

const Footer = () => {
    const navigate = useNavigate()

    const handleClickAgreement = () => {
        navigate('/resume/agreement/user')
    }

    return <FooterStyled>
        <div className="flex justify-between" style={{ maxWidth: 800, margin: '0 auto' }}>
            <div className="flex flex-col">
                <JzImage className="logo-img" src="sldknfijxcbnvjhbu.png" />

                <div onClick={handleClickAgreement} className="mb-22" style={{ color: '#BBB' }}>
                    用户协议
                </div>


                <div className="flex" style={{ paddingBottom: 30 }}>
                    <div style={{ textAlign: 'left' }}>
                        <div style={{ marginBottom: 8 }}>Copyright © 2022 {'了了简历' || config.productName} All Rights Reserved·{config.companyName} <a style={{ color: '#777' }} rel="nofollow"
                            target='_blank'
                            href='http://beian.miit.gov.cn/'>{config.record}</a></div>
                        <div style={{ marginBottom: 8 }}></div>
                    </div>
                </div>
            </div>

            <div className="flex-center flex-col mb-30">
                <JzImage style={{ width: 88, height: 88, marginBottom: 9 }} src="nfdkscxvdfn123123.png" />
                <div style={{ color: '#999', fontSize: 12 }}>微信、手机制作简历</div>
            </div>
        </div>
    </FooterStyled>
}

export default withComponent(Footer)