import { getJzImageUrl } from '@/utils'

const mapVipTypeToInfo = {
    PPT_vip: {
        title: 'VIP会员特权',
        titleIconUrl: getJzImageUrl('ppt_vip33.png'),
        bgUrl: getJzImageUrl('ppt_vip44.png'),
        listIconColor: '#11BBA6',
        list: [
            { txt: '<strong>PPT文件下载 10次/月</strong>' },
            { txt: 'AI生成页数 50页/月' },
            { txt: 'PPT视频格式下载 10次/月' },
            { txt: '<strong>Word、PDF等文档转PPT</strong>' },
            { txt: '自由输入、链接转PPT' },
            { txt: '无水印在线演示' },
            { txt: 'PDF无水印下载' },
            { txt: 'PPT工作台上限 20个' },
            { txt: '文件上传空间 200M' },
            { txt: '回收站保留 15天' },
        ],
    },
    PPT_svip: {
        title: 'SVIP会员特权',
        titleIconUrl: getJzImageUrl('zzz_ppt_svip.png'),
        bgUrl: getJzImageUrl('ppt_svip44.png'),
        listIconColor: '#CB9444',
        list: [
            { txt: '<strong>PPT文件下载 <span style=\'color: #EF333F\'>100次/月</span></strong>' },
            { txt: 'AI生成页数 <span style=\'color: #EF333F\'>1000页/月</span>' },
            { txt: 'PPT视频格式下载 <span style=\'color: #EF333F\'>20次/月</span>' },
            { txt: '<strong>百万PPT模板随意使用</strong>' },
            { txt: '<strong>Word、PDF等文档转PPT</strong>' },
            { txt: '自由输入、链接转PPT' },
            { txt: '无水印在线演示' },
            { txt: 'PDF无水印下载' },
            { txt: '单页版式任意替换' },
            { txt: '智能美化PPT模板' },
            { txt: 'PPT工作台上限 <span style=\'color: #EF333F\'>50个</span>' },
            { txt: '文件上传空间 <span style=\'color: #EF333F\'>2G</span>' },
            { txt: '回收站保留 <span style=\'color: #EF333F\'>30天</span>' },
            { txt: '优先客服支持' },
            { txt: '后续高级功能' },
        ],
    },
    All_vip: {
        title: '全站VIP会员特权',
        titleIconUrl: getJzImageUrl('all_vip33.png'),
        otherUrl: getJzImageUrl('all_vip44_new.png'),
        bgUrl: getJzImageUrl('all_vip44.png'),
        listIconColor: '#DC61C6',
        list: [
            { txt: '<strong>全站模板500+功能使用</strong>' },
            { txt: 'AI生成页数 150页/月' },
            { txt: 'PPT文件下载 20次/月' },
            { txt: 'PPT视频格式下载 20次/月' },
            { txt: '动画PPT文件下载 20次/月' },
            { txt: '动画视频格式下载 20次/月' },
            { txt: '视频下载 50次/月' },
            { txt: '巨幕PPT文件下载 20次/月' },
            { txt: '工作台上限 50个' },
            { txt: '文件上传空间 500M' },
            { txt: '回收站保留 30天' },
        ],
    },
    All_svip: {
        title: '全站SVIP会员特权',
        titleIconUrl: getJzImageUrl('all_svip33.png'),
        otherUrl: getJzImageUrl('other_all_svip.png'),
        bgUrl: getJzImageUrl('all_svip44.png'),
        listIconColor: '#FB716A',
        list: [
            { txt: '<strong>全站模板500+功能使用</strong>' },
            { txt: 'AI生成页数 <span style=\'color: #EF333F\'>3000页/月</span>' },
            { txt: 'PPT文件下载 <span style=\'color: #EF333F\'>200次/月</span>' },
            { txt: 'PPT视频格式下载 <span style=\'color: #EF333F\'>40次/月</span>' },
            { txt: '动画PPT文件下载 <span style=\'color: #EF333F\'>200次/月</span>' },
            { txt: '动画视频格式下载 <span style=\'color: #EF333F\'>40次/月</span>' },
            { txt: '视频下载 <span style=\'color: #EF333F\'>100次/月</span>' },
            { txt: '巨幕PPT文件下载 <span style=\'color: #EF333F\'>200次/月</span>' },
            { txt: '工作台上限 150个' },
            { txt: '文件上传空间 5G' },
            { txt: '回收站保留 60天' },
            { txt: '优先客服支持' },
            { txt: '后续高级功能' },
        ],
    },
    Media_vip: {
        title: 'VIP会员特权',
        titleIconUrl: getJzImageUrl('video_vip33_new.png'),
        bgUrl: getJzImageUrl('video_vip44.png'),
        listIconColor: '#EE5552',
        list: [
            { txt: '<strong>视频文件下载 30次/月</strong>' },
            { txt: 'AI生成页数 50页/月' },
            { txt: '工作台上限 20个' },
            { txt: '文件上传空间 200M' },
            { txt: '视频回收站保留 15天' },
        ],
    },
    Media_svip: {
        title: 'SVIP会员特权',
        titleIconUrl: getJzImageUrl('zzz_vid_svip.png'),
        bgUrl: getJzImageUrl('video_svip44.png'),
        listIconColor: '#D1A058',
        list: [
            { txt: '<strong>视频文件下载 <span style=\'color: #EF333F\'>60次/月</span></strong>' },
            { txt: 'AI生成页数 <span style=\'color: #EF333F\'>1000页/月</span>' },
            { txt: '工作台上限 <span style=\'color: #EF333F\'>50个</span>' },
            { txt: '文件上传空间 <span style=\'color: #EF333F\'>2G</span>' },
            { txt: '视频回收站保留 <span style=\'color: #EF333F\'>30天</span>' },
            { txt: '优先客服支持' },
            { txt: '后续高级功能' },
        ],
    },
    Animation_vip: {
        title: 'VIP会员特权',
        titleIconUrl: getJzImageUrl('ani_vip33.png'),
        bgUrl: getJzImageUrl('ani_vip44.png'),
        listIconColor: '#C872E9',
        list: [
            { txt: '<strong>动画PPT文件下载 10次/月</strong>' },
            { txt: '动画视频格式下载 10次/月' },
            { txt: 'AI生成页数 50页/月' },
            { txt: '工作台上限 20个' },
            { txt: '文件上传空间 200M' },
            { txt: '动画回收站保留 15天' },
        ],
    },
    Animation_svip: {
        title: 'SVIP会员特权',
        titleIconUrl: getJzImageUrl('zzz_ani_svip.png'),
        bgUrl: getJzImageUrl('ani_svip44.png'),
        listIconColor: '#D1A058',
        list: [
            { txt: '<strong>动画PPT文件下载 <span style=\'color: #EF333F\'>100次/月</span></strong>' },
            { txt: '动画视频格式下载 <span style=\'color: #EF333F\'>20次/月</span>' },
            { txt: 'AI生成页数 <span style=\'color: #EF333F\'>1000页/月</span>' },
            { txt: '单页版式任意替换' },
            { txt: '单个PPT页数不限' },
            { txt: '工作台上限 <span style=\'color: #EF333F\'>50个</span>' },
            { txt: '文件上传空间 <span style=\'color: #EF333F\'>2G</span>' },
            { txt: '动画回收站保留 <span style=\'color: #EF333F\'>30天</span>' },
            { txt: '优先客服支持' },
            { txt: '后续高级功能' },
        ],
    },
    Prezi_vip: {
        title: 'VIP会员特权',
        titleIconUrl: getJzImageUrl('prezi_vip33.png'),
        bgUrl: getJzImageUrl('prezi_vip44.png'),
        listIconColor: '#389DE8',
        list: [
            { txt: '<strong>巨幕PPT文件下载 10次/月</strong>' },
            { txt: 'AI生成页数 50页/月' },
            { txt: '工作台上限 20个' },
            { txt: '文件上传空间 200M' },
            { txt: '视频回收站保留 15天' },
        ],
    },
    Prezi_svip: {
        title: 'SVIP会员特权',
        titleIconUrl: getJzImageUrl('zzz_prezi_svip.png'),
        bgUrl: getJzImageUrl('ppt_svip44.png'),
        listIconColor: '#CB9444',
        list: [
            { txt: '<strong>巨幕PPT文件下载 <span style=\'color: #EF333F\'>100次/月</span></strong>' },
            { txt: 'AI生成页数 <span style=\'color: #EF333F\'>1000页/月</span>' },
            { txt: '工作台上限 <span style=\'color: #EF333F\'>50个</span>' },
            { txt: '文件上传空间 <span style=\'color: #EF333F\'>2G</span>' },
            { txt: '回收站保留 <span style=\'color: #EF333F\'>30天</span>' },
            { txt: '优先客服支持' },
            { txt: '后续高级功能' },
        ],
    },
    Resume_vip: {
        title: 'VIP会员特权',
        titleIconUrl: getJzImageUrl('resume_vip33.png'),
        bgUrl: getJzImageUrl('resume_vip44.png'),
        listIconColor: '#2370F2',
        list: [
            { txt: '在线简历下载 15次/月' },
            { txt: 'AI消耗次数 150次/月' },
            { txt: '简历创建份数 5份' },
            { txt: '智能在线编辑' },
            { txt: '一键更换模板' },
            { txt: '调整简历配色、字体' },
        ],
    },
    Resume_svip: {
        title: 'SVIP会员特权',
        titleIconUrl: getJzImageUrl('resume_svip33.png'),
        bgUrl: getJzImageUrl('resume_svip44.png'),
        listIconColor: '#CB9444',
        list: [
            { txt: 'AI模拟面试' },
            { txt: '在线简历下载 <span style=\'color: #EF333F\'>300次/月</span>' },
            { txt: 'AI消耗次数 <span style=\'color: #EF333F\'>3000次/月</span>' },
            { txt: '简历创建份数 <span style=\'color: #EF333F\'>15个</span>' },
            { txt: '会员专属模板 <span style=\'color: #EF333F\'>1000+</span>' },
            { txt: '智能在线编辑' },
            { txt: '一键更换模板' },
            { txt: '调整简历配色、字体' },
            { txt: '优先客服支持' },
        ],
    },
}

export default mapVipTypeToInfo
