import './Deduction.scss'

const Deduction = ({ data }) => {
  const upgradeSVipInfo = data?.resumeRemainingDetail || {}

    return <div className='reduction-wrap'>
    <div className='reduction-bold' style={{ marginRight: 8 }}>
        已抵扣 <span style={{ color: '#EF333F' }}>{upgradeSVipInfo?.remainingSum}</span> 元
    </div>
    <div className='reduction-normal'>(已支付 {upgradeSVipInfo?.payMoney} 元 /{upgradeSVipInfo?.vipDays}天 * 未使用{upgradeSVipInfo?.remainingDays}天)</div>
  </div>;
}

export default Deduction
