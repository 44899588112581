export const vipOrderConfig = [
    { id: 267, text: '终身VIP' },
    { id: 266, text: '年VIP' },
    { id: 265, text: '月VIP' },
    // { id: 30, text: '七天VIP' },
];


export const sVipOrderConfig = [
    { id: 271, text: '终身SVIP' },
    { id: 270, text: '年SVIP' },
    { id: 269, text: '月SVIP' },
]
