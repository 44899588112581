import { uploadResumeByWord } from "@/http/mobileApi"
import styled from "styled-components"
import { clearToast, showToast } from "../../utils/toast"
import { useNavigate } from "react-router-dom"

const UploadResumeStyled = styled.div`
    padding: 40px 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    .title {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 12px;
    }

    .desc {
        font-size: 12px;
        color: #999999;
        margin-bottom: 32px;
    }

    .upload {
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.05);
        border-radius: 8px;
        padding: 21px 0;
        padding-left: 28px;
        padding-right: 20px;

        font-size: 16px;
        color: #666666;
    }

    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
    }
`

const rightSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击导入简历" transform="translate(-321.000000, -227.000000)" fill="#999999" fill-rule="nonzero">
            <g id="返回" transform="translate(321.000000, 227.000000)">
                <path d="M8.99008332,1.62606369 C9.23686819,1.37590766 9.6371048,1.3651263 9.89699915,1.60163368 C10.1568935,1.83814105 10.1837891,2.23761848 9.95794314,2.50682923 L9.91996218,2.54873926 L5.46964055,7.03409505 L9.91603312,11.445126 C10.1571558,11.6843435 10.175354,12.0682104 9.95794314,12.3291658 L9.91996218,12.3714032 C9.68065744,12.612428 9.29679472,12.6304838 9.03592242,12.4129858 L8.99368497,12.3750049 L4.08235298,7.50296354 C3.84132822,7.2636588 3.82327245,6.87979608 4.04077036,6.61892378 L4.07875134,6.57668633 L8.99008332,1.62606369 L8.99008332,1.62606369 Z" id="路径" transform="translate(7.000000, 6.997989) scale(-1, 1) translate(-7.000000, -6.997989) "></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const leftSvg = <div className="flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="点击导入简历" transform="translate(-48.000000, -224.000000)" fill="#FFAB32" fill-rule="nonzero">
            <g id="手机导入" transform="translate(48.000000, 224.000000)">
                <path d="M5.2,2 L14.8,2 C16.015,2 17,2.98499999 17,4.20000001 L17,15.8 C17,17.015 16.015,18 14.8,18 L5.2,18 C3.98499999,18 3,17.015 3,15.8 L3,4.20000001 C3,2.98500001 3.98499999,2 5.2,2 Z M5.2,3.2 C4.9347835,3.2 4.68042959,3.30535684 4.49289321,3.49289322 C4.30535683,3.68042961 4.2,3.93478352 4.2,4.2 L4.2,15.8 C4.2,16.0652165 4.30535683,16.3195704 4.49289321,16.5071068 C4.68042959,16.6946432 4.9347835,16.8 5.2,16.8 L14.8,16.8 C15.0652165,16.8 15.3195704,16.6946432 15.5071068,16.5071068 C15.6946432,16.3195704 15.8,16.0652165 15.8,15.8 L15.8,4.2 C15.8,3.93478352 15.6946432,3.68042961 15.5071068,3.49289322 C15.3195704,3.30535684 15.0652165,3.2 14.8,3.2 L5.2,3.2 Z M8.4,14.8 C8.06862915,14.8 7.8,14.5313709 7.8,14.2 C7.8,13.8686292 8.06862915,13.6 8.4,13.6 L11.6,13.6 C11.9313708,13.6 12.2,13.8686292 12.2,14.2 C12.2,14.5313709 11.9313708,14.8 11.6,14.8 L8.4,14.8 L8.4,14.8 Z" id="形状"></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const UploadResume = () => {
    const navigate = useNavigate()

    const handleUpload = async (e) => {
        const file = e.target.files[0]
        const isLt50M = file.size / 1024 / 1024 < 50;

        // 定义允许的MIME类型
        const allowedTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        // 校验文件大小和MIME类型
        if (!allowedTypes.includes(file.type)) {
            showToast('请上传Word格式的文件', 'fail', 2000);
            return false;
        }
        if (!isLt50M) {
            message.error('简历文件最大为50M！');
            return false;
        }
        const formData = new FormData();
        formData.append('wordFile', file);
        try {
            showToast('上传中...')
            const result = await uploadResumeByWord({
                body: formData
            })
            if (result) {
                navigate(`/resume/mobile/resume?id=${result}`)
            }
        } catch (error) {
            console.log('erroer', error)
            // showToast(error?.msg || '上传失败', 'fail', 2000)
        } finally {
            e.target.value = null;
            clearToast()
        }
        return false;
    }

    return <UploadResumeStyled>
        <div className="title">导入简历，智能识别</div>
        <div className="desc">支持Word格式导入，80%以上内容识别</div>

        <div className="upload flex-center justify-between">
            <div className="flex left" style={{ position: 'relative' }}>
                {leftSvg}
                <div className="pl-16">手机导入</div>

            </div>
            <input accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" onChange={handleUpload} />
            {rightSvg}
        </div>
    </UploadResumeStyled>
}

export default UploadResume