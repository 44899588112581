import styled from "styled-components"
import useResumeData from "@/pages/Mobile/hooks/useResumeData"
import { Input, TextArea } from 'antd-mobile'
import { useEffect, useMemo, useState } from "react";
import { getFieldConfigByModuleName } from "./config";
import { isEmpty } from 'lodash'
import JzButton from "@/components/JzButton";
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible";
import { Module } from "@/common/module/module";
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData";
import useQuery from "@/hooks/useQuery";
import getPlaceholder from "@/common/config/getPlaceholder";
import { PORTFOLIO_FIELD_ENUM } from "@/common/field/field";
import useQueryParam from "@/hooks/useQueryParam";
import { RightOutline } from 'antd-mobile-icons'
import QueryString from "qs";
import { useNavigate } from "react-router-dom";
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"


const ModuleEditStyled = styled.div`
    padding: 20px;
    margin-bottom: 52px;

    .svg-icon {
        /* position: absolute;
        right: 10px; */
        margin-left: 4px;
    }
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 15px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
    }


    .module-title-item {
        position: relative;
        padding-bottom: 17px;
        border-bottom: 1px solid #E8E9EB;
        margin-bottom: 17px;
    }
    .module-title-item-last {
        padding-top: 20px;
        padding-bottom: 0;
        border: none;
    }

    label {
        display: block;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #888888;
    }
`



const moduleName = Module.PORTFOLIO

const PortfolioItem = () => {
    const navigate = useNavigate()
    const { contentIndex, id: resumeId } = useQuery()
    const { data: resumeData } = useResumeData()
    const { onChangeByPath } = useUpdateResumeData()
    const basicInfoData = useMemo(() => {
        return resumeData?.[moduleName]?.data?.links?.[contentIndex] || {}
    }, [resumeData, moduleName, contentIndex])
    const onFinish = () => {
        let path = `${moduleName}.data.links[${contentIndex}]`
        onChangeByPath(path, {
            title: basicInfoData.title,
            link: basicInfoData.link
        })
    }

    const handleInputChange = (type, value) => {
        let path = `${moduleName}.data.links[${contentIndex}]`
        onChangeByPath(path, {
            ...basicInfoData,
            [type]: value
        }, false)
    }

    const handleClickTitle = () => {
        const params = QueryString.stringify({
            id: resumeId,
            contentIndex
        })
        
        navigate(`/resume/mobile/edit/portfolio_text?${params}`)
    }

    return <ModuleEditStyled>
        <NavigationHeader 
        style={{ width: "100vw", left: "-20px", top: "-20px" }}
        title={document.title}></NavigationHeader>
        <div>
            <div onClick={handleClickTitle} className='module-title-item flex-center justify-between pr-12'>
                <div className="flex-1">
                    <label className='pb-12'>作品描述</label>
                    <Input style={{
                        '--font-size': 15,
                        pointerEvents: 'none'
                    }} value={basicInfoData.title} onChange={(value) => handleInputChange('title', value)} placeholder='请输入' />
                </div>
                <RightOutline fontSize={17} color="#CCCCCC" className="svg-icon" />
            </div>
            <div className='module-title-item'>
                <label className='pb-12'>作品链接</label>
                <Input style={{
                    '--font-size': 15,
                    '--color': '#11BBA6'
                }} value={basicInfoData.link} onChange={(value) => handleInputChange('link', value)} placeholder='请输入' />
            </div>

        </div>
        <div className="resume-wrap-footer flex">
            <JzButton onClick={() => onFinish()} className="flex-1" $type="primary">保存</JzButton>
        </div>

    </ModuleEditStyled>
}

export default PortfolioItem