import {Modal} from 'antd';
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import withComponent from '@/hoc/withComponent';
import useForceUpdate from '@/hooks/useForceUpdate';
import {useSelector} from 'react-redux';
import isLogin from '@/common/isLogin';
import LoginModal from '@/components/user/LoginModal';
import OriginPrice from '@/components/PayModal/OriginPrice';
import {vipTypeEnum} from '@/const/pay';

export const expiresTime = 30 * 60 * 1000;

export default withComponent((props) => {
    const {getContainer = null, visible = false, zIndex = 999, canSinglePage, onSuccess: onSuccess$1, vipType } = props;
    const that = useRef({ telephoneBindModalCancelCallback: undefined}).current;
    const user = useSelector((state) => state.user);
    const [, update] = useForceUpdate();

    const login = isLogin(user);

    const onCancel = () => {
        props.onCancel?.();
    };
    const onClickUserCancel = useCallback(() => {
        onCancel();
    }, [canSinglePage]);

    const onSuccess = useCallback((...param) => {
        onSuccess$1?.(...param);
        clearTimeout(that.checkPayTimer);
    }, []);
    useLayoutEffect(() => {
        if (visible && login) {
            const pathname = window.location.pathname;
            const baseUrl = (url) => {
                let finalUrl = `${url}?callback_url=${encodeURIComponent(window.location.href)}`;
                if (vipType) {
                    finalUrl += `&vipType=${vipType}`;
                }
                return finalUrl;
            };

            if (pathname === '/resume') {
                window.location.href = baseUrl('/pptx/resume-index');
            } else if (pathname === '/resume/resume') {
                if (window.tabKey === 'resume') {
                    window.location.href = baseUrl('/pptx/resume-edit');
                } else {
                    window.location.href = baseUrl('/pptx/resume-interview');
                }
            } else {
                window.location.href = baseUrl('/pptx/resume-index');
            }
        }
    }, [visible, vipType])

    if (!login && visible) {
        return <LoginModal zIndex={zIndex} getContainer={null} visible onSucessd={update}
                           onSuccess={update}
                           onCancel={props.onCancel}/>;
    }

    return null
});
