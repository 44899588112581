import {getResumeDataById} from "@/http/api";
import styled from "styled-components"
import {useEffect, useMemo, useRef, useState, useLayoutEffect} from "react";
import Resume from "@/components/resume/index";
import {createStore} from "@/components/resume/redux/store";
import {ResumeReduxContext} from "@/components/resume/redux/context";
import {Provider} from "react-redux";
import {ResumeDetailInstanceContext} from "@/pages/ResumeDetail/context";
import dispatchResumeData from "@/common/resume/dispatchResumeData";
import useWidth from "../../hooks/useWidth";
import useQueryParam from "@/hooks/useQueryParam";
import { Toast } from "antd-mobile";
import JzButton from "@/components/JzButton";
import { useNavigate } from "react-router-dom";
import useDownload from "../../hooks/useDownload";
import TemplateConfig from "./components/TemplateConfig";
import { produce } from "immer";
import {Scrollbars} from 'react-custom-scrollbars';
import FlexStyled from "@/styled/FlexStyled";
import { clearToast, showToast } from "../../utils/toast";
import { useDispatch } from "react-redux"
import { fetchResumeUserList } from "../../mobileSlice";
import useResumeEdit from "@/hooks/useResumeEdit";

const backSvg = <div className="flex-center" dangerouslySetInnerHTML={{__html: `
    <?xml version="1.0" encoding="UTF-8"?>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="预览状态-切换模板" transform="translate(-30.000000, -726.000000)" fill="#555550" fill-rule="nonzero">
                <g id="编组-3备份" transform="translate(0.000000, 718.000000)">
                    <g id="编组-5" transform="translate(20.000000, 8.000000)">
                        <g id="文本备份-18" transform="translate(10.000000, 0.000000)">
                            <path d="M12,3.54 C16.6645017,3.54 20.46,7.33549835 20.46,12 C20.46,16.6645016 16.6645016,20.46 12,20.46 C7.33549837,20.46 3.54,16.6645016 3.54,12 C3.54,7.33549837 7.33549836,3.54 12,3.54 Z M11.997556,4.86 C8.06165033,4.86 4.85755601,8.06409433 4.85755601,12 C4.85755601,15.9375031 8.06080349,19.14 11.997556,19.14 C15.935059,19.14 19.137556,15.9367525 19.137556,12 C19.137556,8.06249696 15.9343085,4.86 11.997556,4.86 Z M10.9508717,8.53576347 C11.2090995,8.28022552 11.6288803,8.2827442 11.8766792,8.54132191 C11.9997767,8.66703852 12.067332,8.83486008 12.067332,9.00610999 C12.067332,9.14798296 12.0235646,9.28374563 11.9406061,9.39649102 L11.8711105,9.47647015 L9.83951121,11.341222 L15.590224,11.341222 C15.955744,11.341222 16.251446,11.636924 16.251446,12.002444 C16.251446,12.3295863 16.0115901,12.6014826 15.6974584,12.6525905 L15.590224,12.661222 L9.91376291,12.5588491 L11.851795,14.5017691 C11.9776912,14.6276653 12.0453361,14.7932542 12.0453361,14.9670061 C12.0453361,15.1413072 11.9773607,15.3091215 11.8515723,15.4349086 C11.6218315,15.6622564 11.2671116,15.6868615 11.0101505,15.5085517 L10.9188076,15.4321736 L7.9220774,12.4008147 L7.939651,12.4407971 C7.90857956,12.4097256 7.88285539,12.3752203 7.85813881,12.3325397 L7.8216,12.264 L7.80816383,12.2470461 L7.79692384,12.2253501 C7.71186145,12.015057 7.74045232,11.776837 7.87107335,11.5940032 L7.94483257,11.5075875 L10.9508717,8.53576347 Z" id="形状"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`}} />

const PreviewStyled = styled.div`
    padding: 15px 18px;
    margin-bottom: 300px;
    
    .resume-wrap-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 12px;
        padding-bottom: 24px;
        background-color: #FFFFFF;
        z-index: 101;

        span {
            font-size: 11px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #494949;
        }
    }
`
const xPadding = 36

const Preview = () => {
    const id = useQueryParam('id');
    const bodyRef = useRef();
    const dispatch = useDispatch()
    const resumeRef = useRef();
    const instance = useRef({templateId: undefined, name: undefined, resumeRef}).current;
    const [resumeLoading, setResumeLoading] = useState(true);
    const navigate = useNavigate()
    const resumeWidth = useWidth() - xPadding
    const resumeStore = useMemo(() => createStore(), []);
    const { down } = useDownload({
        resumeName: instance.name,
        resumeId: id,
    })
    const fetchData = async () => {
        setResumeLoading(true)
        showToast('加载中...')
        let resumeData = await getResumeDataById(id).catch((err) => {
            Toast.show({
                icon: 'fail',
                content: err.msg || '系统繁忙，请重试',
            })
        })
        if (!resumeData) return;
        clearToast()
        dispatchResumeData(resumeStore.dispatch, resumeData)
        instance.templateId = resumeData.template.templateId
        instance.name = resumeData.resume.name
        setResumeLoading(false)
    }
    const { onChange, onInit } = useResumeEdit(resumeStore);

    window.fetchMobileData = fetchData

    useEffect(() => {
        fetchData()
    }, []);

    // 更新我的简历
    const handleBack = () => {
        dispatch(fetchResumeUserList())

        navigate(`/resume/mobile/resume?id=${id}`, { replace: true })
    }

    const handleDownload = () => {
        down()
    }


    return <ResumeDetailInstanceContext.Provider value={instance}>
        <Provider context={ResumeReduxContext} store={resumeStore}>
            {/* <GlobalStyle/> */}
            <PreviewStyled ref={bodyRef}>
                <div className="preview-resume-wrap" style={{pointerEvents: resumeLoading ? "none" : undefined}}>
                    {!resumeLoading && <Resume  
                                            openEdit={false}
                                            resumeRef={resumeRef}
                                            getModuleContentToolContainer={() => bodyRef.current}
                                            width={resumeWidth} 
                                            store={resumeStore}
                                            onChange={onChange}
                                            onInit={onInit}
                                        />
                                        }
                </div>
                <TemplateConfig />
                <div className="resume-wrap-footer flex" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.13)'}}>
                    <div onClick={handleBack} className="flex-center flex-col" style={{ marginRight: 28 }}>
                        {backSvg}
                        <span style={{ marginTop: 4 }}>返回编辑</span>
                    </div>
                    <JzButton style={{ height: 44 }} onClick={handleDownload} className="flex-2" $type="primary">下载简历</JzButton>
                </div>
            </PreviewStyled>

            
        </Provider>
    </ResumeDetailInstanceContext.Provider>
}

// withStore

// withResumeInstance
export default Preview