import { useEffect,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResumeUserList } from '../mobileSlice'
import { getResumeDataById } from '@/http/api'
import { setData } from '../pages/ResumeEdit/editSlice'
import { useLocation } from 'react-router-dom';

// 使用自定义钩子来触发异步 action
const useResumeUserList = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const times = useRef(0)
    const resumeUserList = useSelector(state => state.mobile.resumeUserList)

    const getResumeData = async (resumeId) => {
        const data = await getResumeDataById(resumeId).catch((err) => {
            // message.error(err.msg)
        })
        dispatch(setData(data))
    }
    useEffect(() => {
        // 请求最近的数据

        // 模板 刷新bug        
        if(times.current===0 && location.pathname!=='/resume/mobile/resume'){
            times.current =1
            dispatch(fetchResumeUserList())
        }
    }, [dispatch,location.pathname])

    useEffect(() => {
        if (resumeUserList && resumeUserList.length > 0) {
            getResumeData(resumeUserList[0].id)
        }
    }, [resumeUserList])
}


export default useResumeUserList