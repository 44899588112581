import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '@/components/Modal'
import FlexStyled from '@/styled/FlexStyled'
import { getJzImageUrl } from '@/utils'

const ItemStyled = styled(FlexStyled)`
  width: 255px;
  border: 1px solid #F0F0F0;
  padding: 0 15px;
  align-items: center;
`

const TitleStyled = styled(ItemStyled)`
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
`
const DesStyled = styled(ItemStyled)`
  height: 37px;
  font-size: 14px;

  font-weight: 400;
  color: #7E848E;
  line-height: 37px;
  text-align: center;
`

const ButtonStyled = styled.div`
  display: inline-block;
  width: 146px;
  height: 32px;
  background: #00BCA6;
  border-radius: 4px;
  opacity: 0.8;

  font-size: 14px;

  font-weight: 500;
  color: #FFFFFF;
  line-height: 32px;

`
const ButtonStyled2 = styled(ButtonStyled)`
  background: #FECF91;
  font-size: 14px;

  font-weight: 500;
  color: #984506;

`

const BoxStyled = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`

const mapVipTypeToMember = {
    PPT_vip: {
        title: 'PPT会员权益对比',
        imgUrl: getJzImageUrl('qydb_ppt.png'),
    },
    PPT_svip: {
        title: 'PPT会员权益对比',
        imgUrl: getJzImageUrl('qydb_ppt.png'),
    },
    All_vip: {
        title: '全站会员权益对比',
        imgUrl: getJzImageUrl('qydb_all.png'),
    },
    All_svip: {
        title: '全站会员权益对比',
        imgUrl: getJzImageUrl('qydb_all.png'),
    },
    Media_vip: {
        title: '视频会员权益对比',
        imgUrl: getJzImageUrl('qydb_video.png'),
    },
    Media_svip: {
        title: '视频会员权益对比',
        imgUrl: getJzImageUrl('qydb_video.png'),
    },
    Animation_vip: {
        title: '动画会员权益对比',
        imgUrl: getJzImageUrl('qydb_ani.png'),
    },
    Animation_svip: {
        title: '动画会员权益对比',
        imgUrl: getJzImageUrl('qydb_ani.png'),
    },
    Prezi_vip: {
        title: '巨幕会员权益对比',
        imgUrl: getJzImageUrl('qydb_prezi.png'),
    },
    Prezi_svip: {
        title: '巨幕会员权益对比',
        imgUrl: getJzImageUrl('qydb_prezi.png'),
    },
    Resume_vip: {
        title: '简历会员权益对比',
        imgUrl: getJzImageUrl('kdsjnfjkds_dksjd.png'),
    },
    Resume_svip: {
        title: '简历会员权益对比',
        imgUrl: getJzImageUrl('kdsjnfjkds_dksjd.png'),
    },
}

function Content(props) {
    const { vipType } = props
    const info = mapVipTypeToMember[vipType]

    return (
        <BoxStyled>
            <div>
                <img src={info?.imgUrl} alt='权益' style={{ width: 770 }} />
                <div style={{ height: 40 }} />
            </div>
        </BoxStyled>
    )
}

const successIcon = (
    <div
        style={{
            width: 24, height: 24, display: 'inline-block', position: 'relative',
        }}
        dangerouslySetInnerHTML={{
            __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='权益对比' transform='translate(-844.000000, -481.000000)' fill='#7E848E' fill-rule='nonzero'>
            <g id='VIP会员备份' transform='translate(844.000000, 481.000000)'>
                <g id='编组-13' transform='translate(5.500000, 7.000000)'>
                    <polygon id='路径' points='5.75254493 10 0 4.97383444 0.955762222 3.94386299 5.56057559 7.96701554 11.8669725 0 13 0.844433873'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
        }}
    />
)
const closeIcon = (
    <div
        style={{
            width: 24, height: 24, display: 'inline-block', position: 'relative',
        }}
        dangerouslySetInnerHTML={{
            __html: `<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='付费页面' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='权益对比' transform='translate(-588.000000, -481.000000)' fill='#7E848E' fill-rule='nonzero'>
            <g id='VIP会员备份-6' transform='translate(588.000000, 481.000000)'>
                <g id='编组-11' transform='translate(6.500000, 6.500000)'>
                    <polygon id='路径' points='0.0420650096 10.0249851 10.001765 0 10.957935 0.975014872 0.997426092 11'></polygon>
                    <polygon id='路径' points='10.0535373 10.9566478 0 1.02736466 0.946462715 0.0433521713 11 9.97181737'></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>`,
        }}
    />
)

export default function (props) {
    const {
        visible, onCancel, zIndex = 10000, vipType,
    } = props
    const info = mapVipTypeToMember[vipType]
    const title = info?.title || 'VIP/SVIP权益对比'
    const onSuccess = () => {
    }

    return (
        <Modal
            visible={visible}
            footerVisible={false}
            onSuccess={onSuccess}
            maskClosable={false}
            onCancel={onCancel}
            showCancel={false}
            titleCenter
            zIndex={zIndex}
            title={title}
            style={{
                width: 990,
                height: 544,
            }}
            render={() => <Content {...props} />}
        />
    )
}
