import store from 'store'
import styled from 'styled-components'
import { Tooltip, Typography } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'
import { getJzImageUrl } from '@/utils'
import mapVipTypeToInfo from '../PayModal/config/text'
import { mapVipIdToConfig, mapVipIdToText } from '../PayModal/config/vip'
import mapTypeToTabData from '../PayModal/config/tab'
import MemberRightsModal from '../MemberRightsModal'
import './UserCard.scss'

const bgUrl = getJzImageUrl('bjdnfdniu.png')

const UserCardStyled = styled.div`
    position: relative;
    width: 100%;
    height: 84px;
    padding: 10px 8px;
    padding-bottom: 6px;
    padding-right: 6px;
    box-sizing: border-box;
    img {
        vertical-align: middle;
    }
    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 84px;
    }
    .cd-title {

        font-weight: bold;
        font-size: 16px;
        color: #286F67;
        .cd-subtile {
            font-size: 10px;
            font-weight: normal;
        }
    }
    .cd-content {
        position: relative;
        z-index: 1;
    }
    .cd-btn-wrap {
        height: 32px;
        background: #FFFFFF;
        border-radius: 18px;
    }
    .cd-btn {
        height: 26px;
        background: #FEECBB;
        border-radius: 13px;
        padding: 7px 10px;
        font-weight: 500;
        font-size: 12px;
        color: #9F823F;
    }
    .cd-btn:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    .all-vip {
        font-size: 12px;
        color: #E463CB;
        background-image: linear-gradient(45deg, #E36CD1 0%, #F05F66 40%, #F0A85F 100%); // 背景线性渐变
        color: #E463CB; // 兜底颜色，防止文字裁剪不生效
        background-clip: text;
        -webkit-background-clip: text; // 背景被裁减为文字的前景色
        -webkit-text-fill-color: transparent; // 文字填充为透明，优先级比color高。
    }
`

const IconStyled = styled.div`
    img {
        width: 20px;
        height: 20px;
        opacity: ${(props) => (props.isActive ? 1 : 0.4)};
    }
    :hover {
        cursor: pointer;
    }
`

const getUserVipId = () => {
    const user = store.get('user')
    return user?.vipTypeList || []
}

const isVip = () => {
    const vipIdList = getUserVipId()
    return vipIdList.some((id) => mapVipIdToConfig[id])
}

// 全站vip
export const isAllVip = () => {
    const vipIdList = getUserVipId()
    const allVipList = [218, 219, 220, 222, 223, 224, 334, 335, 336, 338, 339, 340]
    return vipIdList.find((id) => allVipList.includes(id))
}
// 全站终身VIP
const isAllLifeVip = () => {
    const vipIdList = getUserVipId()
    return vipIdList.find((id) => id === 224 || id === 340)
}


// 普通状态下显示4个图标
const getIconInfoList = (vipIdList) => {
    let list = ['All_svip', 'PPT_svip', 'Prezi_svip', 'Animation_svip', 'Media_svip', 'Resume_svip'].map((item) => ({
        type: item,
        ...mapVipTypeToInfo[item],
        title: mapTypeToTabData[item].title,
    }))

    const vipTypeList = vipIdList.map((id) => mapVipIdToConfig[id]?.vipType)
    let allVip = null
    for (let i = 0; i < vipTypeList.length; i++) {
        const vipType = vipTypeList[i]
        if (vipType) {
            const [type] = vipType.split('_')
            const item = {
                active: true,
                type: vipType,
                ...mapVipTypeToInfo[vipType],
                title: mapTypeToTabData[vipType].title.replace(' VIP', ' SVIP'),
            }
            list = [item, ...list.filter((item) => !item.type.includes(type))]
            if (item.title.includes('全站')) {
                allVip = {
                    ...item,
                    allVipText: '尊享全站权益',
                }
            }
        }
    }
    if (allVip) {
        return [allVip]
    }
    return list
}

const getTextList = (vipIds) => {
    if (!vipIds) return ''
    const list = vipIds.map((vipId) => mapVipIdToText[vipId].text)
    const allVip = list.find((text) => text.includes('全站'))
    if (allVip) {
        return allVip
    }
    return list.join('、')
}

function UserCard({ onClickIcon }) {
    const vipIds = getUserVipId()
    const vip = isVip()
    const isAll = isAllVip()
    const list = getIconInfoList(vipIds)
    const textList = getTextList(vipIds)
    const [visible, setVisible] = useState(false)

    const handleClickBtn = () => {
        if (isAllLifeVip()) {
            setVisible(true)
        } else if (vip) {
            const vipIdList = getUserVipId()
            if (vipIdList.length === 1) {
                const config = mapVipIdToConfig[vipIdList[0]]
                const type = config.vipType.split('_')[0]
                onClickIcon(`${type}_svip`)
            } else {
                onClickIcon('All_svip')
            }
        } else {
            const pathname = document.location.pathname
            if (pathname === '/pptx/prezi') {
                onClickIcon('Prezi_svip')
            } else if (pathname === '/pptx/') {
                onClickIcon('PPT_svip')
            } else if (pathname === '/pptx/mg') {
                onClickIcon('Animation_svip')
            } else if (pathname === '/pptx/video') {
                onClickIcon('Media_svip')
            } else {
                onClickIcon('All_svip')
            }
        }
    }

    const handleClickIcon = (type) => {
        if (isAllLifeVip()) {
            setVisible(true)
            return
        }
        let t = type
        if (t.includes('_vip')) {
            t = t.replace('_vip', '_svip')
        }
        onClickIcon(t)
    }

    let btnText = vip ? '解锁更多权益' : '立即开通'
    if (isAllLifeVip()) {
        btnText = '查看权益'
    }
    return (
        <UserCardStyled className='user-card'>
            <img className='bg-img' src={bgUrl} alt="bg" />

            <div className='cd-content'>
                <div className='cd-title flex items-center mb-8'>
                    {
                        !vip ? (
                            <>
                                开通会员
                                <span className='cd-subtile ml-4'>全站四大类目海量下载</span>
                            </>
                        ) : <Typography.Text className='cd-title' ellipsis>{textList}</Typography.Text>
                    }
                </div>

                <div className='cd-btn-wrap flex justify-between items-center'>
                    <div className='cd-icon-list flex ml-12 mb-2'>
                        {list.map((item, index) => {
                            const isActive = item.active
                            const cls = classNames({
                                'cd-icon-item': true,
                                'mr-2': !isAllVip(),
                            })
                            const title = isAllLifeVip() ? '全站 SVIP会员' : item.title
                            return (
                                <>
                                    <Tooltip title={title}>
                                        <IconStyled isActive={isActive} onClick={() => handleClickIcon(item.type)} className={cls} key={index}>
                                            <img src={item.titleIconUrl} alt="icon" />
                                        </IconStyled>
                                    </Tooltip>
                                    {item.allVipText && <span className='all-vip ml-4 mt-3'>{item.allVipText}</span>}
                                </>
                            )
                        })}
                    </div>

                    <div onClick={handleClickBtn} className='cd-btn flex-center mr-3'>
                        {btnText}
                    </div>
                </div>
            </div>
            <MemberRightsModal vipType={mapVipIdToConfig[isAll]?.vipType} visible={visible} onCancel={() => setVisible(false)} />
        </UserCardStyled>
    )
}

export default UserCard
