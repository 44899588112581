import store from 'store'
import { difference } from 'lodash'
import { mapVipIdToConfig } from './vip'

const mapTypeToTabData = {
    PPT_vip: {
        title: 'PPT VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 135deg, #05DFD1 0%, #02BBA4 100%)',
        },
    },
    PPT_svip: {
        title: 'PPT SVIP会员',
        style: {
            color: '#984506',
            background: '#FECF91',
        },
    },
    All_vip: {
        title: '全站 VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 135deg, #BC7FFF 0%, #FA428E 100%)',
        },
    },
    All_svip: {
        title: '全站 SVIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 135deg, #FB9B48 0%, #FA428E 100%)',
        },
    },
    Media_vip: {
        title: '视频 VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 135deg, #EF7470 0%, #E54744 100%)',
        },
    },
    Media_svip: {
        title: '视频 SVIP会员',
        style: {
            color: '#984506',
            background: '#FECF91',
        },
    },
    Animation_vip: {
        title: '动画 VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 135deg, #F48ADC 0%, #A05DF5 100%)',
        },
    },
    Animation_svip: {
        title: '动画 SVIP会员',
        style: {
            color: '#984506',
            background: '#FECF91',
        },
    },
    Prezi_vip: {
        title: '巨幕 VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 315deg, #5C7CFE 0%, #29CEDD 100%)',
        },
    },
    Prezi_svip: {
        title: '巨幕 SVIP会员',
        style: {
            color: '#984506',
            background: '#FECF91',
        },
    },
    Resume_vip: {
        title: '简历 VIP会员',
        style: {
            color: '#ffffff',
            background: 'linear-gradient( 45deg, #689AF1 0%, #1063F2 59%, #0A5FF0 100%)',
        },
    },
    Resume_svip: {
        title: '简历 SVIP会员',
        style: {
            color: '#984506',
            background: '#FECF91',
        },
    },
}

const user = store.get('user')

try {
    if (user && user.vipTypeList) {
        // 如果有VIP，则全站相关VIP都显示升级和本类型会员显示升级
        const configList = user.vipTypeList.map((vipId) => mapVipIdToConfig[vipId]).filter((v) => v)
        const allList = ['All_vip', 'All_svip']
        if (configList && configList.length > 0) {
            allList.forEach((key) => {
                mapTypeToTabData[key].title = `升级 ${mapTypeToTabData[key].title}`
            })
            const allVipList = Object.keys(mapTypeToTabData)
            difference(allVipList, allList).forEach((key) => {
                const config = configList.find((config) => config.vipType === key)
                if (config) {
                    const vipType = config.vipType
                    const vipTypePrefix = vipType.split('_')[0]
                    const pos = `${vipTypePrefix}_vip`
                    const pos2 = `${vipTypePrefix}_svip`
                    mapTypeToTabData[pos].title = `升级 ${mapTypeToTabData[pos].title}`
                    mapTypeToTabData[pos2].title = `升级 ${mapTypeToTabData[pos2].title}`
                }
            })
        }
    }
} catch (error) {
    console.log('error', error)
}

export default mapTypeToTabData
