// 通用提示
import FlexStyled from '@/styled/FlexStyled'
import { BtnStyleEl } from '@/components/ErrorModal'

export default function (props) {
    const { onCancel, payInfo } = props
    return (
        <FlexStyled $flexDirection='column' $alignItems='flex-start' $justifyContent='flex-start' style={{ padding: '20px', width: 400 }}>
            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{payInfo.tipTitle}</div>
            <FlexStyled $justifyContent='center' style={{ marginTop: 15, fontSize: 14, color: '#999999' }}>{payInfo.tipText}</FlexStyled>
            {
                payInfo.upgrade ? (
                    <FlexStyled
                        $justifyContent='flex-end'
                        style={{
                            marginTop: 20, fontSize: 16, color: '#007AFF', width: '100%',
                        }}
                    >
                        {/* <Pointer onClick={props.onCancel}>升级会员</Pointer> */}
                        <BtnStyleEl onClick={onCancel}>升级</BtnStyleEl>
                    </FlexStyled>
                ) : ''
            }
        </FlexStyled>
    )
}
