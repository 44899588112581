export const vipIdConfigList = [
    { id: 11, text: 'PPT月VIP', vipType: 'PPT_vip' },
    { id: 12, text: 'PPT年VIP', vipType: 'PPT_vip' },
    { id: 13, text: 'PPT终身VIP', vipType: 'PPT_vip' },
    { id: 20, text: 'PPT月SVIP', vipType: 'PPT_svip' },
    { id: 21, text: 'PPT年SVIP', vipType: 'PPT_svip' },
    { id: 22, text: 'PPT终身SVIP', vipType: 'PPT_svip' },
    { id: 202, text: '动画月VIP', vipType: 'Animation_vip' },
    { id: 203, text: '动画年VIP', vipType: 'Animation_vip' },
    { id: 204, text: '动画终身VIP', vipType: 'Animation_vip' },
    { id: 206, text: '动画月SVIP', vipType: 'Animation_svip' },
    { id: 207, text: '动画年SVIP', vipType: 'Animation_svip' },
    { id: 208, text: '动画终身SVIP', vipType: 'Animation_svip' },
    { id: 218, text: '全站月VIP', vipType: 'All_vip' },
    { id: 219, text: '全站年VIP', vipType: 'All_vip' },
    { id: 220, text: '全站终身VIP', vipType: 'All_vip' },
    { id: 222, text: '全站月SVIP', vipType: 'All_svip' },
    { id: 223, text: '全站年SVIP', vipType: 'All_svip' },
    { id: 224, text: '全站终身SVIP', vipType: 'All_svip' },
    { id: 234, text: '视频月VIP', vipType: 'Media_vip' },
    { id: 235, text: '视频年VIP', vipType: 'Media_vip' },
    { id: 236, text: '视频终身VIP', vipType: 'Media_vip' },
    { id: 238, text: '视频月SVIP', vipType: 'Media_svip' },
    { id: 239, text: '视频年SVIP', vipType: 'Media_svip' },
    { id: 240, text: '视频终身SVIP', vipType: 'Media_svip' },
    { id: 250, text: '巨幕月VIP', vipType: 'Prezi_vip' },
    { id: 251, text: '巨幕年VIP', vipType: 'Prezi_vip' },
    { id: 252, text: '巨幕终身VIP', vipType: 'Prezi_vip' },
    { id: 254, text: '巨幕月SVIP', vipType: 'Prezi_svip' },
    { id: 255, text: '巨幕年SVIP', vipType: 'Prezi_svip' },
    { id: 256, text: '巨幕终身SVIP', vipType: 'Prezi_svip' },

    { id: 265, text: '简历月VIP', vipType: 'Resume_vip' },
    { id: 266, text: '简历年VIP', vipType: 'Resume_vip' },
    { id: 267, text: '简历终身VIP', vipType: 'Resume_vip' },
    { id: 269, text: '简历月SVIP', vipType: 'Resume_svip' },
    { id: 270, text: '简历年SVIP', vipType: 'Resume_svip' },
    { id: 271, text: '简历终身SVIP', vipType: 'Resume_svip' },
]

export const mapVipIdToConfig = {
    11: { id: 11, text: 'PPT月VIP', vipType: 'PPT_vip' },
    12: { id: 12, text: 'PPT年VIP', vipType: 'PPT_vip' },
    13: { id: 13, text: 'PPT终身VIP', vipType: 'PPT_vip' },
    20: { id: 20, text: 'PPT月SVIP', vipType: 'PPT_svip' },
    21: { id: 21, text: 'PPT年SVIP', vipType: 'PPT_svip' },
    22: { id: 22, text: 'PPT终身SVIP', vipType: 'PPT_svip' },
    202: { id: 202, text: '动画月VIP', vipType: 'Animation_vip' },
    203: { id: 203, text: '动画年VIP', vipType: 'Animation_vip' },
    204: { id: 204, text: '动画终身VIP', vipType: 'Animation_vip' },
    206: { id: 206, text: '动画月SVIP', vipType: 'Animation_svip' },
    207: { id: 207, text: '动画年SVIP', vipType: 'Animation_svip' },
    208: { id: 208, text: '动画终身SVIP', vipType: 'Animation_svip' },
    218: { id: 218, text: '全站月VIP', vipType: 'All_vip' },
    219: { id: 219, text: '全站年VIP', vipType: 'All_vip' },
    220: { id: 220, text: '全站终身VIP', vipType: 'All_vip' },
    222: { id: 222, text: '全站月SVIP', vipType: 'All_svip' },
    223: { id: 223, text: '全站年SVIP', vipType: 'All_svip' },
    224: { id: 224, text: '全站终身SVIP', vipType: 'All_svip' },
    234: { id: 234, text: '视频月VIP', vipType: 'Media_vip' },
    235: { id: 235, text: '视频年VIP', vipType: 'Media_vip' },
    236: { id: 236, text: '视频终身VIP', vipType: 'Media_vip' },
    238: { id: 238, text: '视频月SVIP', vipType: 'Media_svip' },
    239: { id: 239, text: '视频年SVIP', vipType: 'Media_svip' },
    240: { id: 240, text: '视频终身SVIP', vipType: 'Media_svip' },

    250: { id: 250, text: '巨幕月VIP', vipType: 'Prezi_vip' },
    251: { id: 251, text: '巨幕年VIP', vipType: 'Prezi_vip' },
    252: { id: 252, text: '巨幕终身VIP', vipType: 'Prezi_vip' },
    254: { id: 254, text: '巨幕月SVIP', vipType: 'Prezi_svip' },
    255: { id: 255, text: '巨幕年SVIP', vipType: 'Prezi_svip' },
    256: { id: 256, text: '巨幕终身SVIP', vipType: 'Prezi_svip' },

    265: { id: 265, text: '简历月VIP', vipType: 'Resume_vip' },
    266: { id: 266, text: '简历年VIP', vipType: 'Resume_vip' },
    267: { id: 267, text: '简历终身VIP', vipType: 'Resume_vip' },
    269: { id: 269, text: '简历月SVIP', vipType: 'Resume_svip' },
    270: { id: 270, text: '简历年SVIP', vipType: 'Resume_svip' },
    271: { id: 271, text: '简历终身SVIP', vipType: 'Resume_svip' },
}

export const mapVipIdToText = {
    11: { id: 11, text: 'PPT-VIP会员' },
    12: { id: 12, text: 'PPT-VIP会员' },
    13: { id: 13, text: 'PPT-VIP会员' },
    20: { id: 20, text: 'PPT-SVIP会员' },
    21: { id: 21, text: 'PPT-SVIP会员' },
    22: { id: 22, text: 'PPT-SVIP会员' },
    202: { id: 202, text: '动画VIP会员' },
    203: { id: 203, text: '动画VIP会员' },
    204: { id: 204, text: '动画VIP会员' },
    206: { id: 206, text: '动画SVIP会员' },
    207: { id: 207, text: '动画SVIP会员' },
    208: { id: 208, text: '动画SVIP会员' },
    218: { id: 218, text: '全站VIP会员' },
    219: { id: 219, text: '全站VIP会员' },
    220: { id: 220, text: '全站VIP会员' },
    222: { id: 222, text: '全站SVIP会员' },
    223: { id: 223, text: '全站SVIP会员' },
    224: { id: 224, text: '全站SVIP会员' },
    234: { id: 234, text: '视频VIP会员' },
    235: { id: 235, text: '视频VIP会员' },
    236: { id: 236, text: '视频VIP会员' },
    238: { id: 238, text: '视频SVIP会员' },
    239: { id: 239, text: '视频SVIP会员' },
    240: { id: 240, text: '视频SVIP会员' },

    250: { id: 250, text: '巨幕VIP会员' },
    251: { id: 251, text: '巨幕VIP会员' },
    252: { id: 252, text: '巨幕VIP会员' },
    254: { id: 254, text: '巨幕SVIP会员' },
    255: { id: 255, text: '巨幕SVIP会员' },
    256: { id: 256, text: '巨幕SVIP会员' },

    265: { id: 265, text: '简历VIP会员' },
    266: { id: 266, text: '简历VIP会员' },
    267: { id: 267, text: '简历VIP会员' },
    269: { id: 269, text: '简历SVIP会员' },
    270: { id: 270, text: '简历SVIP会员' },
    271: { id: 271, text: '简历SVIP会员' },
}
