import store from 'store';
import {svipLastId, vipLastId, vip_life} from '@/const/pay';

export default (value) => {
    const user = value || store.get('user');
    const isExpired = new Date(user?.editableVipTime).getTime() < new Date().getTime();
    return !!user?.editableVipTime && !isExpired;
}


export const isSVIP = () => {
    const user = store.get('user');
    const allSvip = [222, 223, 224]
    const svip = [269, 270, 271]
    return user.vipType === 20 || user.vipType === 21 || user.vipType === svipLastId || user.vipType === 22 || allSvip.includes(user.vipType) || svip.includes(user.vipType);
};

export const isLifeDVip = () => {
    const user = store.get('user');
    return user?.vipType === vip_life || user?.vipType === 267
}

export const isDVIP = () => {
    const user = store.get('user');
    const dVip = [11, 12, 13, 14, 19, vipLastId, 30, 265, 266, 267]
    return dVip.includes(user.vipType);
};

export const hasReport = ()=>{
    const user = store.get('user');
    return user.enterpriseMbtiMobileState === 1
}

export const isCommonVIP = (value) => {
    const user = value || store.get('user');
    const isExpired = new Date(user?.editableVipTime).getTime() < new Date().getTime();
    return !!user?.editableVipTime && !isExpired;
};
