// 展示的

import { Module } from "@/common/module/module"
import JzButton from "@/components/JzButton"
import { uploadFile } from "@/http/api"
import useResumeData from "@/pages/Mobile/hooks/useResumeData"
import useUpdateResumeData from "@/pages/Mobile/hooks/useUpdateResumeData"
import { Ellipsis } from "antd-mobile"
import { isEmpty } from "lodash"
import FiledManagePopup from "../components/FiledManagePopup"
import useResumeFieldVisible from "@/pages/Mobile/hooks/useResumeFieldVisible"
import { useState } from "react"
import {
    TitleStyled,
    TargetItemImageWrap,
    TargetLinkItem,
    ButtonStyled,
    PortfolioListStyled,
    UploadStyled,
} from './styled'
import { useNavigate } from "react-router-dom"
import useQueryParam from "@/hooks/useQueryParam"
import NavigationHeader from "@/pages/Mobile/components/NaviGationHeader/index.js"


const addSvg = <div className="add-svg flex-center" dangerouslySetInnerHTML={{
    __html: `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>切片</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="编辑校作品展示-填写内容" transform="translate(-125.000000, -563.000000)" fill="#999999">
            <g id="搜索模板备份" transform="translate(125.000000, 563.000000)">
                <path d="M8.46666667,2 C8.57712362,2 8.66666667,2.08954305 8.66666667,2.2 L8.666,7.333 L13.8,7.33333333 C13.9104569,7.33333333 14,7.42287638 14,7.53333333 L14,8.46666667 C14,8.57712362 13.9104569,8.66666667 13.8,8.66666667 L8.666,8.666 L8.66666667,13.8 C8.66666667,13.9104569 8.57712362,14 8.46666667,14 L7.53333333,14 C7.42287638,14 7.33333333,13.9104569 7.33333333,13.8 L7.333,8.666 L2.2,8.66666667 C2.08954305,8.66666667 2,8.57712362 2,8.46666667 L2,7.53333333 C2,7.42287638 2.08954305,7.33333333 2.2,7.33333333 L7.333,7.333 L7.33333333,2.2 C7.33333333,2.08954305 7.42287638,2 7.53333333,2 L8.46666667,2 Z" id="形状结合"></path>
            </g>
        </g>
    </g>
</svg>
`}} />

const moduleName = Module.PORTFOLIO

const PortfolioList = () => {
    const resumeId = useQueryParam('id')
    const { data: resumeData } = useResumeData()
    const data = resumeData?.[moduleName]?.data || {}
    const { fieldList, onChangeFieldList } = useResumeFieldVisible({ moduleName })
    const [fieldManagePopupVisible, setFieldManagePopupVisible] = useState(false)
    const { onChangeByPath } = useUpdateResumeData()
    const navigate = useNavigate()

    const handleChange = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file);

        const fileURL = await uploadFile({ body: formData });
        const length = data?.images?.length || 0

        onChangeByPath(`${moduleName}.data.images[${length}]`, { src: fileURL })
    }

    const handleFieldChange = (fl) => {
        onChangeFieldList(fl)
        setFieldManagePopupVisible(false)
    }

    const handleEditLink = (index) => {
        navigate(`/resume/mobile/edit/portfolio_item?id=${resumeId}&contentIndex=${index}`)
    }

    const handleAddLink = () => {
        const index = data?.links?.length || 0
        navigate(`/resume/mobile/edit/portfolio_item?id=${resumeId}&contentIndex=${index}`)
    }

    if (fieldList.length <= 0 || isEmpty(data)) return <div />

    return <PortfolioListStyled className="p-20">
        <NavigationHeader
        style={{ width: "100vw", left: "-20px", top: "-20px" }}
        title={document.title}></NavigationHeader>
        {
            fieldList[1].checked && <div>
                <TitleStyled>
                    图片作品
                </TitleStyled>
                <TargetItemImageWrap>
                    {
                        data.images?.map((item, index) => {
                            return <div className="img-item">
                                <img key={index} src={item.src} />
                            </div>
                        })
                    }

                    <UploadStyled className="flex-center">
                        {addSvg}

                        <input accept="image/*" type="file" onChange={handleChange} />
                    </UploadStyled>
                </TargetItemImageWrap>
            </div>
        }
        {
            fieldList[0].checked && <>
                <div>
                    {
                        data.links?.map((item, index) => {
                            return <TargetLinkItem onClick={() => handleEditLink(index)} key={index}>
                                <Ellipsis direction='end' rows={4} content={item.title} />
                                <div className="pt-12" style={{ color: '#11BBA6' }}>{item.link}</div>
                            </TargetLinkItem>
                        })
                    }
                </div>
                <ButtonStyled onClick={handleAddLink} className="flex-center">
                    {addSvg}
                    <div className="pl-8">添加作品链接</div>
                </ButtonStyled>
            </>
        }

        <div className="resume-wrap-footer flex">
            <JzButton onClick={() => setFieldManagePopupVisible(true)} className="flex-1" style={{ marginRight: 8 }} >信息管理</JzButton>
            <JzButton onClick={() => onFinish()} className="flex-2" $type="primary">保存</JzButton>
        </div>
        <FiledManagePopup
            visible={fieldManagePopupVisible}
            fieldList={fieldList}
            onChange={handleFieldChange}
            onCancel={() => setFieldManagePopupVisible(false)}
        />
    </PortfolioListStyled>

}

export default PortfolioList