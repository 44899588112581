import { useNavigate } from 'react-router-dom';
import { fetchResumeSearch, getDataFromTemplate } from "@/http/api"
import { InfiniteScroll, Popup, Toast } from "antd-mobile"
import config from "@/pages/Home/config"
import { useImmerReducer } from "use-immer"
import { isEmpty } from "lodash"
import RatioImg from "@/pages/Mobile/components/RatioImg"
import styled from 'styled-components';
import useQueryParam from '@/hooks/useQueryParam';

// import './index.scss'

const MobileUseList = config.find(item => item.title === '风格').children

const limit = 6

const initialState = {
    dataList: [],
    filter: {
        use: '全部', // 用途
        searchWord: '', // 关键词
        position: '全部',
        profession: '全部',
        style: '全部',
    },
    hasMore: true, // 
    current: 1,
    loading: false,
}

const listReducer = (draft, action) => {
    switch (action.type) {
        case 'init': {
            draft.current = 1
            draft.dataList = []
            draft.hasMore = true
            break
        }
        case 'loading': {
            draft.loading = true
            break
        }
        case 'changed': {
            draft.current += 1
            draft.dataList.push(...action.data)
            if (action.data.length < limit) {
                draft.hasMore = false
            } else {
                draft.hasMore = true
            }
            draft.loading = false
            break
        }
        case 'updateFilter': {
            Object.assign(draft.filter, action.filter);
            draft.current = 1
            draft.dataList = []
            draft.hasMore = true
            break
        }
        default:
            break;
    }
}

const ResumeListStyled = styled.div`
    padding: 10px;
    background-color: #fff;
    .header {
        font-size: 17px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #2C2C2C;
        margin-bottom: 16px;
        padding-top: 10px;
    }
    .resume-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 9px;
        row-gap: 12px;
        .resume-item-img {
            border-radius: 8px;
            border: 1px solid #E2E2E2;
        }
        .resume-item-title {
            padding-left: 2px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #343542;
        }
    }
    .resume-use {
        overflow-x: scroll;
        margin-bottom: 16px;
    }
    .header, .resume-use {
        padding-left: 5px;
    }
`

const TagStyled = styled.div`
    width: 44px;
    height: 28px;
    background: #F7F7F8;
    border-radius: 4px;
    margin-right: 8px;
    flex-shrink: 0;
    ${props => props.$active ? `
        background: ${props.theme.colors.primary};
        color: #fff;
    ` : ''}
`

const SwitchTemplateList = () => {
    const resumeId = useQueryParam('id')
    const navigate = useNavigate()
    const [state, dispatch] = useImmerReducer(listReducer, initialState)
    const { style: filterStyle } = state.filter


    const fetchList = async () => {
        const res = await fetchResumeSearch({
            query: {
                page: state.current,
                limit: 6,
                ...state.filter
            },
        })
        const { dataList } = res;
        dispatch({
            type: 'changed',
            data: dataList || []
        })
    };

    const handleClickTemplate = (template) => {
        navigate(`/resume/mobile/resume/preview?id=${resumeId}&themeId=${template.id}`)
    }

    return (
        <ResumeListStyled>
            <div className="resume-wrap">
                <div className='header'>模板风格</div>
                <div className="resume-use flex-center justify-start close-scrollbar">
                    {
                        MobileUseList.map(item => {
                            return <TagStyled
                                onClick={() => dispatch({
                                    type: 'updateFilter',
                                    filter: {
                                        style: item
                                    }
                                })}
                                $active={item === filterStyle}
                                key={item}
                                className="flex-center"
                            >{item}</TagStyled>
                        })
                    }
                </div>
                <div className="resume-list">
                    {
                        state.dataList.map(item => {
                            return <div key={item.id} className="resume-item" onClick={() => handleClickTemplate(item)}>
                                <RatioImg className="resume-item-img" src={item.cover} ratio={[794, 1103]} />
                            </div>
                        })
                    }
                </div>
                <InfiniteScroll loadMore={fetchList} hasMore={state.hasMore} />
            </div>
        </ResumeListStyled>
    )
}


export default SwitchTemplateList