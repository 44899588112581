import axios from "axios";
import store from "@/redux/store"
import {fetchAnonymousUserThunk, logoutUserThunk} from "@/redux/thunk/user";
import strictStringify from "@/common/strictStringify";
import ErrorCode from '@/components/ErrorModal/ErrorCode';
import errorSlice from "@/redux/services/errorSlice";
import { isMobile } from "@/pages/Mobile/utils";

let request = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

let fetchAnonymousUserManage = {pendingAnonymousUserPromise: Promise.resolve()}
let isPendingAnonymousUser = false;


const excludePendingFetchAnonymousUserUrl = ['/ppt/user/login_anonymous', '/resume/user/fetchUserThunk'];
const isExcludeLogin = (url) => {
    return excludePendingFetchAnonymousUserUrl.includes(url)
}
const pendingFetchAnonymousUser = () => {
    const {dispatch} = store;
    const {user} = store.getState();
    try {
        if (!user && !isPendingAnonymousUser) {
            isPendingAnonymousUser = true;
            fetchAnonymousUserManage.pendingAnonymousUserPromise = dispatch(fetchAnonymousUserThunk()).finally(() => {
                isPendingAnonymousUser = false
            })
        }
    } catch (e) {

    }
}

request.interceptors.request.use(async function (config) {
    if (!isExcludeLogin(config.url) && window.location.pathname !== "/down") {
        pendingFetchAnonymousUser();
        await fetchAnonymousUserManage.pendingAnonymousUserPromise
    }
    const {user} = store.getState();
    if (user) {
        config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json;';
        config.headers['User-Id'] = user.id;
        config.headers['User-Token'] = user.accessToken;
        config.headers['Web-Type'] = 'resume';
    }
    return config;
});


request.interceptors.response.use(
    response => {
        switch (response.data.code) {
            case 0: {
                return response.data.data;
            }
            case 1001: {
                break
            }
            case 1009: {
                const {dispatch} = store;
                if (isMobile()) {
                    dispatch(errorSlice.actions.setCode(1009))
                    return 
                }
                dispatch(logoutUserThunk()).then(() => {
                    window.location.reload();
                })
                break
            }
            default: {
                try {
                    const {dispatch} = store;
                    if (ErrorCode.find((code) => code === response.data.code)) {
                        dispatch(errorSlice.actions.setCode(response.data.code))
                    }
                } catch (e) {
                    return Promise.reject(error.response)
                }
                return Promise.reject(response.data);
            }
        }
    },
    error => {
        return Promise.reject(error);
    }
)
let http = {
    get: (url, options = {}) => {
        return request.get(url + strictStringify(options.query), options.config)
    },
    post: (url, options) => {
        return request.post(url + strictStringify(options.query), options.body, options.config)
    },
    upload: (url, options = {}) => {
        return request.post(url + strictStringify(options.query), options.body, {
            ...options.config,
            headers: {
                ...options.config?.headers,
                'Content-Type': 'multipart/form-data', // 确保设置了正确的Content-Type
            },
        });
    }
}

export default http;
