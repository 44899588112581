import styled from "styled-components";
import Header from "@/pages/Home/Header/Header";
import { useEffect, useState } from "react";
import HeaderFixed from "./HeaderFixed";
import Tabs, { TabItem } from "./Tabs";
import { ImportContent } from "@/components/ImportModal";
import ImportVideoModal from "@/components/ImportVideoModal";
import JzImage from "@/components/JzImage";
import TabItemOne from "@/pages/HomeWelcome/components/Tabs/TabItemOne";

const ContainerStyled = styled.div`
  position: relative;
  background: #C2E3F5;
  height: 380px;
  display: flex;
  flex-direction: column;
  .header-tabs {
    width: 724px;
    margin: 0 auto;

    position: absolute;
    left: 0;
    right: 0;
    top: 149px;
  }
`;
const NavContainerStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
`
const NavTextStyled = styled.div`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  padding-top: 17px;
  padding-bottom: 9px;
  ${props => props.$isActive ? 'border-bottom: 2px solid #FFFFFF;' : ''}
`
const TitleStyled = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 36px;
  letter-spacing: 3px;
`
const TitleDesStyled = styled.div`
  position: absolute;
  top: 114px;
  right: 0;
  left: 0;
  text-align: center;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  letter-spacing: 2px;
`

const SearchWrapperStyled = styled.div`
    padding: 16px 14px;
    background: #ffffff;
    border-radius: 0px 12px 12px 12px;
`

const SearchPositionStyled = styled.div`
  /* position: absolute;
  top: 205px;
  right: 0;
  left: 0; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
`
const SearchContainerStyled = styled.div`
  border: 3px solid #CFDCF2;
  width: 724px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InputStyled = styled.input`
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 28px;
  height: 60px;
  border: none;
  outline: none;
  font-size: 16px;


  ::placeholder {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    line-height: 16px;
  }
`
const SearchButtonStyled = styled.div`
  flex-shrink: 0;
  width: 110px;
  height: 42px;
  background: linear-gradient( 135deg, #689AF1 0%, #1063F2 59%, #0A5FF0 100%);
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1px;
  margin-right: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
const TagContainerStyled = styled.div`
  
  margin-top: 30px;
  display: flex;
  justify-content: center;
`
const TagTextStyled = styled.div`
  margin-right: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  cursor: pointer;
  transition: all 200ms;

  ${props => props.hover && `
    &:hover {
      color: #0A5FF0;
    }
  `}
  
`
const tagList = [ 'JAVA简历', '前端开发简历', '产品经理简历', '实习简历', '求职简历模板']

export default ({ onUpdateValueAndSearch, fixedHeaderVisible, searchValue }) => {
    const [value, setValue] = useState('')
    const [activeTab, setActiveTab] = useState('first');
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setValue(searchValue)
    }, [searchValue])
    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleSearch = (value) => {
        setValue(value)
        onUpdateValueAndSearch(value)
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            handleSearch(value)
        }
    }

    const handleClickVideo = (e) => {
        e.stopPropagation();
        setVisible(true)
    }

    return <ContainerStyled>
        <JzImage style={{ position: 'absolute', height: '100%', left: '50%', transform: 'translateX(-50%)'}} src="xcjhdfgoir.png" />
        {
            fixedHeaderVisible && <HeaderFixed onKeyDown={handleKeyDown} value={value} onChange={(e) => setValue(e.target.value)} onSearch={() => handleSearch(value)} />
        }
        <Header />
        <NavContainerStyled>
        </NavContainerStyled>
        <TitleStyled>改简历，练面试，接Offer</TitleStyled>
        <TitleDesStyled>HR推荐的专业AI简历面试工具</TitleDesStyled>

        <Tabs className={"header-tabs"} value={activeTab} onChange={(value) => setActiveTab(value)}>
            <Tabs.List>
                <Tabs.Tab value="first">
                    <TabItem url="sdjfksdhfb1.png"
                             text='搜索模板'
                             active={'first' === activeTab}
                             activeUrl="jltabhome1.png"
                       />
                </Tabs.Tab>
                <Tabs.Tab value="third">
                    <TabItem url="dksjaflb2.png" activeUrl="jltabhome2.png" text='一键生成' active={'third' === activeTab} />
                  </Tabs.Tab>
                <Tabs.Tab value="second">
                    <TabItem url="dksjaflb2.png" activeUrl="jltabhome3.png" text='导入简历' active={'second' === activeTab} />
                  </Tabs.Tab>
                
            </Tabs.List>
            <Tabs.Panel value="first">
                <SearchWrapperStyled>
                  <SearchPositionStyled>
                      <SearchContainerStyled>
                          <InputStyled onKeyDown={handleKeyDown} value={value} onChange={handleChange} placeholder="在1000+专业简历中搜索" />
                          <SearchButtonStyled onClick={() => onUpdateValueAndSearch(value)}>搜索模板</SearchButtonStyled>
                      </SearchContainerStyled>
                  </SearchPositionStyled>
                </SearchWrapperStyled>
                <TagContainerStyled>
                    <TagTextStyled>搜索模板:</TagTextStyled>
                    {tagList.map((text) => <TagTextStyled hover onClick={() => handleSearch(text)} key={text}>{text}</TagTextStyled>)}
                </TagContainerStyled>
            </Tabs.Panel>
            <Tabs.Panel value="second">
                <div  style={{ background: '#FFF', borderRadius: 10,padding:'16px 0 0 0 ' }}>
                    <ImportContent isHome onClickVideo={handleClickVideo} style={{ marginBottom: 0 }} contentStyle={{ height: 80 }} />
                </div>
            </Tabs.Panel>
            <Tabs.Panel value="third">
              <TabItemOne></TabItemOne>
            </Tabs.Panel>
        </Tabs>

        <ImportVideoModal isHome visible={visible} onCancel={() => setVisible(false)} />
    </ContainerStyled>

}
