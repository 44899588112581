import http from "./http";
import pptHttp from "./pptHttp";
import parse from "@/common/resume/parse";
import errorSlice from "@/redux/services/errorSlice";
import store from "@/redux/store";





export const aigetUsedPointHistory = (query, body) => http.get('/resume/ai/getUsedPointHistory', {query, body})
export const gzhQRCode = (query, body) => http.get('/ppt/login/wechat/qr', {query, body})
export const gzhState = (query, body) => http.get('/ppt/login/wechat', {query, body})
export const getUserInfo = (query, body) => http.get('/ppt/user/token', {query, body})
export const login_anonymous = (query, body) => http.get('/ppt/user/login_anonymous', {query, body})
export const fetchResumeSearch = (options) => http.get('/resume/resume/search', options)
export const create_order_edit = (query, body) => http.post(`/ppt/pptEditedOrder/create_order`, {query, body})
export const getUserRemainingDetail = (query, body) => http.get(`/ppt/order/getUserManyVipRemainingDetail`, {query, body})
export const pay_alipay = (query, body) => http.post(`/ppt/order/pay_alipay`, {query, body})
export const pay_order_query = (query, body) => http.get(`/ppt/order/pay_order_query`, {query, body})
export const pay_weixin = (query, body) => http.post(`/ppt/order/pay_weixin_auto`, {query, body})
export const bindPhonePc = (query, body) => http.get(`/login/bindPhonePc`, {query, body})
export const sendCode = (query, body) => http.get(`/shortMessage/sendCode`, {query, body});
export const getUsedPointHistory = (query, body) => http.get(`/resume/ai/getUsedPointHistory`, {query, body});
export const pptAuthorizedDownloadById = (query, body) => http.get(`/resume/templateStationOrder/pptAuthorizedDownloadById`, {
    query,
    body
});
export const getUserDownloadHistory = (query, body) => http.get(`/resume/templateStation/getUserDownloadHistory`, {
    query,
    body
});
export const getUserPayHistory = (query, body) => http.get(`/ppt/order/getUserPayHistory`, {query, body});
export const modifyResume = (options) => {
    /*            const res = await Promise.resolve({
                    "text": "已经为您更新了基本信息和工作经历，请查看是否满意。",
                    "resumeBody": {
                        "userData": {
                            "self_evaluation": {
                                "id": "self_evaluation",
                                "data": {
                                    "title_text": "自我评价",
                                    "details": "作为一位的职场新人，我以积极主动的工作态度来迎接工作。团队配合能力善于沟通，具备一定的活动策划和组织协调能力。工作态度评价：拥有良好的心态和责任感，吃苦耐劳，擅于管理时间。自主学习能力：制定切实可行学习计划，积极主动学习岗位技能。"
                                },
                                "visible": true,
                                "sort": 4
                            },
                            "基本信息": {
                                "data": {
                                    "姓名": "张三",
                                    "邮箱": "zhangsan88@qq.com",
                                    "生日月份": 6,
                                    "手机号": "13888888888",
                                    "生日年份": 1990,
                                    "性别": "男"
                                },
                                "id": "基本信息",
                                "sort": 0
                            },
                            "avatar": {
                                "id": "avatar",
                                "data": {
                                    "src": "https://oss.jianzeppt.com/user/2400853/fbca9dd7-1664-4053-b786-27e4c4e279a2.png"
                                },
                                "sort": 1
                            },
                            "honors_and_awards": {
                                "id": "honors_and_awards",
                                "data": {
                                    "title_text": "荣誉证书",
                                    "details": "英语四级"
                                },
                                "visible": true,
                                "sort": 13
                            },
                            "language_proficiency": {
                                "id": "language_proficiency",
                                "data": {
                                    "title_text": "语言能力",
                                    "details": "英语四级"
                                },
                                "visible": true,
                                "sort": 12
                            },
                            "project_experience": {
                                "id": "project_experience",
                                "data": {
                                    "title_text": "项目经验",
                                    "content": [
                                        {
                                            "start_date": "2022-03-01T16:00:00.000Z",
                                            "end_date": "2023-03-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "name": "熊猫办公",
                                            "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                        },
                                        {
                                            "start_date": "2023-03-01T16:00:00.000Z",
                                            "end_date": "2023-06-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "name": "熊猫办公",
                                            "details": "了解客户需求，提供合适房源；陪同客户看房，促成二手房买卖租赁业务"
                                        }
                                    ]
                                },
                                "visible": true,
                                "sort": 7
                            },
                            "skills": {
                                "id": "skills",
                                "data": {
                                    "title_text": "技能特长",
                                    "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                },
                                "visible": true,
                                "sort": 10
                            },
                            "educational_background": {
                                "id": "educational_background",
                                "data": {
                                    "title_text": "教育背景",
                                    "content": [
                                        {
                                            "start_date": "2010-03-01T16:00:00.000Z",
                                            "end_date": "2014-03-01T16:00:00.000Z",
                                            "school": "熊猫高中",
                                            "major": "软件工程",
                                            "education": "高中",
                                            "logo": "LOGO",
                                            "details": "主修课程：工程力学、岩土工程、结构工程、市政工程、工程制图测量、计算机应用、专业实验、结构设计、工程规划、室内设计、施工、管理等相关课程"
                                        },
                                        {
                                            "start_date": "2018-03-01T16:00:00.000Z",
                                            "end_date": "2022-03-01T16:00:00.000Z",
                                            "school": "熊猫大学",
                                            "major": "软件工程",
                                            "education": "本科",
                                            "logo": "LOGO2",
                                            "details": "主修课程：工程力学、岩土工程、结构工程、市政工程、工程制图测量、计算机应用、专业实验、结构设计、工程规划、室内设计、施工、管理等相关课程"
                                        }
                                    ]
                                },
                                "visible": true,
                                "sort": 3
                            },
                            "portfolio": {
                                "id": "portfolio",
                                "data": {
                                    "title_text": "作品展示",
                                    "imgLayoutType": 3,
                                    "images": [
                                        {
                                            "src": "https://oss.jianzeppt.com/user/2400853/242b5486-4083-4769-b69e-2c872c14c1b7.jpg"
                                        }
                                    ],
                                    "links": [
                                        {
                                            "title": "",
                                            "link": ""
                                        }
                                    ]
                                },
                                "visible": true,
                                "sort": 5
                            },
                            "interest": {
                                "id": "interest",
                                "data": {
                                    "title_text": "兴趣爱好",
                                    "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                },
                                "visible": true,
                                "sort": 11
                            },
                            "job_interest": {
                                "id": "job_interest",
                                "data": {
                                    "title_text": "求职意向",
                                    "job_type": "全职",
                                    "role": "软件开发工程师",
                                    "city": "杭州",
                                    "salary": 18,
                                    "job_status": "DIMISSION_SEARCH_JOB"
                                },
                                "visible": true,
                                "sort": 2
                            },
                            "internship_experience": {
                                "id": "internship_experience",
                                "data": {
                                    "title_text": "实习经历",
                                    "content": [
                                        {
                                            "start_date": "2022-03-01T16:00:00.000Z",
                                            "end_date": "2023-03-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "activity_name": "熊猫办公",
                                            "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                        },
                                        {
                                            "start_date": "2023-03-01T16:00:00.000Z",
                                            "end_date": "2023-06-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "activity_name": "熊猫办公",
                                            "details": "了解客户需求，提供合适房源；陪同客户看房，促成二手房买卖租赁业务"
                                        }
                                    ]
                                },
                                "visible": true,
                                "sort": 8
                            },
                            "2945bf69-13e9-42d5-8934-d4526fc45f46": {
                                "id": "2945bf69-13e9-42d5-8934-d4526fc45f46",
                                "data": {
                                    "title_text": "自定义标题",
                                    "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                },
                                "visible": true,
                                "sort": 14,
                                "type": "custom_module"
                            },
                            "work_experience": {
                                "visible": true,
                                "data": {
                                    "title_text": "工作经验",
                                    "content": [
                                        {
                                            "end_date": "2016-01-01T16:00:00.000Z",
                                            "title_text": "工作经验",
                                            "company": "阿里巴巴集团",
                                            "details": "参与开发和维护阿里巴巴电商平台的后台管理系统和前端展示系统。负责系统性能优化和安全漏洞修复。参与团队内部培训和技术分享。",
                                            "position": "软件工程师",
                                            "start_date": "2014-01-01T16:00:00.000Z"
                                        },
                                        {
                                            "end_date": "2023-06-01T16:00:00.000Z",
                                            "title_text": "工作经验2",
                                            "company": "腾讯科技有限公司",
                                            "details": "参与开发和维护微信公众号平台和小程序平台。负责开发和维护微信公众号平台的第三方服务商平台。参与团队内部技术研究和创新项目。",
                                            "position": "高级软件工程师",
                                            "start_date": "2016-01-01T16:00:00.000Z"
                                        }
                                    ]
                                },
                                "id": "work_experience",
                                "sort": 6
                            },
                            "campus_activities": {
                                "id": "campus_activities",
                                "data": {
                                    "title_text": "校园活动",
                                    "content": [
                                        {
                                            "start_date": "2022-03-01T16:00:00.000Z",
                                            "end_date": "2023-03-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "activity_name": "熊猫办公",
                                            "details": "负责客户接待、咨询工作，为客户提供专业的房地产置业咨询服务；"
                                        },
                                        {
                                            "start_date": "2023-03-01T16:00:00.000Z",
                                            "end_date": "2023-06-01T16:00:00.000Z",
                                            "role": "软件开发",
                                            "activity_name": "熊猫办公",
                                            "details": "了解客户需求，提供合适房源；陪同客户看房，促成二手房买卖租赁业务"
                                        }
                                    ]
                                },
                                "visible": true,
                                "sort": 9
                            }
                        },
                        "changePart": [
                            {
                                "slotName": "姓名",
                                "blockName": "基本信息"
                            },
                            {
                                "slotName": "邮箱",
                                "blockName": "基本信息"
                            },
                            {
                                "slotName": "生日月份",
                                "blockName": "基本信息"
                            },
                            {
                                "slotName": "手机号",
                                "blockName": "基本信息"
                            },
                            {
                                "slotName": "生日年份",
                                "blockName": "基本信息"
                            },
                            {
                                "blockName": "work_experience",
                                "moduleContentIndex": 0
                            },
                            {
                                "blockName": "work_experience",
                                "moduleContentIndex": 1
                            }
                        ]
                    },
                    "conversationList": [],
                    "conversationId": "3e09f612-5ee4-4e9d-a7dd-9be7d5adf3e9"
                })*/

/*    const {dispatch} = store;
    dispatch(errorSlice.actions.setCode(10001))
    return Promise.reject()*/
    return http.post(`/resume/ai/modifyResume`, options);
}

export const uploadResumeAttachment = (options) => {
    return http.upload(`/resume/ai/modifyResumeAttachment`, options)
}

export const modifyResumeWithJD = (options) => {
    return http.post(`/resume/ai/modifyResumeWithJD`, options)
}





export const replaceFontFamily = (options) => http.post(`/ppt/ai/replaceFontFamily`, options);
export const getResumePageByResumeId = (options) => http.get(`/resume/resumePage/getResumePageByResumeId`, options);
export const getDataFromTemplate = (options) => http.get(`/resume/resume/getDataFromTemplate`, options);
export const getResumeUserData = (options) => http.get(`/resume/resume/getResumeUserData`, options);

export const listResumeUser = (options) => http.get(`/resume/resume/listResumeUser`, options);
export const saveResumeUserData = (options) => http.post(`/resume/resume/saveResumeUserData`, options);
export const editResumeInfo = (options) => http.post(`/resume/resume/editResumeInfo`, options);

export const saveResumePageUserData = (options) => http.post(`/resume/resume/saveResumePageUserData`, options);
export const downResume = (options) => http.get(`/resume/resume/downResume`, options);
export const downPdf = (options) => http.post(`/resume/download/downPdf`, options);
export const uploadFile = (options) => {
    // const {file} = options.body;
    // return URL.createObjectURL(file);
    return http.upload('/resume/upload/uploadImage', options)
}
export const getSearchIcon = (options) => pptHttp.post(`/ppt/templateStation/searchIcon`, options);

export const copyResumeOfUser = (query) => http.get(`/resume/resume/copyResumeOfUser`, {query});
export const deleteResumeOfUser = (query) => http.get(`/resume/resume/deleteResumeOfUser`, {query});
export const editResumeName = (query) => http.get(`/resume/resume/editResumeName`, {query});
export const changeResumeTemplate = (options) => http.get(`/resume/resume/changeResumeTemplate`, options);
export const getDownHistory = (options) => http.post(`/resume/download/getDownHistory`, options);
export const update_keyword = (options) => {
    return http.post(`/ppt/user/update_keyword`, options);
}

export const getTemplate = (options) => {
    return http.get(`/resume/resume/getTemplate`, options).then((res) => {
        return res.pagesData[0].body
    })
}

export const updateResumeOrigin = (options) => http.get(`/resume/develop/updateResumeOrigin`, options);
export const getResumeDataById = async (resumeId)=>{
    const res = await getResumeUserData({query: {resumeId}});
    const templateStr =  await getTemplate({query:{templateId: res.templateId}})
    return  parse(res,templateStr);
}

// 获取简历JD
export const getJobDescription = (options) => {
    return http.get(`/resume/job/resumeJobDescription`, options);
}

// 模板 转换成 简历 里的JD
export const addJobDescription = (options) => {
    return http.post(`/resume/job/resumeJobDescription/add`, options)
}

export const deleteJobDescription = (options) => {
    return http.post(`/resume/job/resumeJobDescription/delete`, options)
}
// 模板 简历 JD 更新
export const updateJobDescription = (options) => {
    return http.post(`/resume/job/resumeJobDescription/update`, options)
}
export const getJobTypesAll = (options) => {
    return http.get(`resume/job/jobTypesAll`, options)
}

export const getJobDescriptionsByTypeId = (options) => {
    return http.get(`/resume/job/JobDescriptions`, options)
}
export const jobStt = (options) => {
    return http.upload(`/resume/job/stt`, options)
}
export const getInterviewQuestionList = (options) => {
    return http.get(`/resume/interview/list_questions`, options)
}
export const getInterviewQuestionListTemplate = (options) => {
    return http.get(`/resume/interview/list_template_questions`, options)
}

export const generateReference = (options) => {
    return http.get(`/resume/interview/generate_reference`, options)
}

export const updateAnswerQuestion = (options) => {
    return http.upload(`/resume/interview/answer_question`, options)
}

export const generateQuestions = (options) => {
    return http.get(`/resume/interview/generate_questions`, options)
}

export const getInterviewReportPdf = (options) => {
    return http.get(`/resume/interview/getInterviewReportPdf`, options)
}

export const reachUserCrateResumeLimit = (options) => {
    return http.get(`/resume/resume/reachUserCrateResumeLimit`, options)
}

export const userBehaviorLog = (options) => {
    return http.get(`/system/user_behavior/log`, options)
}

export const getJobSearchDescription = (options) =>{
    return http.get(`/resume/job/jobDescription/search`, options)
}
export const getEnterpriseMobile = (options) =>{
    return http.get(`/resume/user/addEnterpriseMbtiMobile`, options)
}
export const deleteResumeOrigin = (options) => {
    const { id, checkCode } = options
    return http.get(`/resume/resume/deleteResumeOrigin?id=${id}&checkCode=${encodeURIComponent(checkCode)}`)
}

// 获取职位信息
export const getSearchTagsJson = (options) =>{
    return http.get(`/resume/resume/searchTagsJson`, options)
    
}
// 搜索简历
export const postSearchResume = (options) =>{
    return http.post(`/resume/resume/searchV2`, options)
}