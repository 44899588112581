import { useState } from "react";
import Pointer from "../styled/Pointer";
import productName from "../const/productName";
import { darkLogo_svg, logo_svg, new_logo_svg, whiteLogo } from "../const/logo";

export default ({ isDark, isWhite, isO, href = "/resume" } = {}) => {
  const [hover, setHover] = useState(false);
  const a = () => {
    if (isWhite) {
      return new_logo_svg;
    }
    if (isDark) {
      return new_logo_svg;
    } else {
      return new_logo_svg;
    }
  };

  return (
    <Pointer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ position: "relative" }}
    >
      <a alt={productName} href={href}>
        <div
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            top: 0,
            zIndex: 9,
            display: "block",
          }}
        >
          <img
            alt={productName}
            src="https://oss.jianzeppt.com/user/484779/ec7e5777-1299-40ba-a288-19d5aed1c9c8.png"
            style={{ width: "100%", height: "100%", opacity: 0 }}
          />
        </div>
        {a()}
        {
          <div
            style={{
              position: "absolute",
              top: -12,
              left: 40,
              visibility: hover ? undefined : "hidden",
            }}
          >
            <img
              alt={productName}
              src="https://oss.jianzeppt.com/user/484779/ec7e5777-1299-40ba-a288-19d5aed1c9c8.png"
              width={232}
              height={96}
            />
          </div>
        }
      </a>
    </Pointer>
  );
};
